import angular from 'angular';
import $ from 'jquery';
import module from '../module';
import moment from 'moment';
import * as enums from '../../enums';
import * as rxjs from 'rxjs';
import * as operators from 'rxjs/operators';
import Swal from 'sweetalert2';

export default module.controller('ScheduleController', [
  '$scope',
  '$rootScope',
  '$localStorage',
  '$modal',
  '$timeout',
  'calendarJobManagerService',
  'AlertService',
  'calendarProviderManagerService',
  'EnterpriseFilterService',
  '$window',
  'FormatHelperService',
  'AuthenticationService',
  'trackingService',
  'generalDataService',
  'OverlapService',
  'scheduleTotalWorkersService',
  'TimeFormatService',
  'PERMISSIONS',
  'ForceFilterService',
  '$location',
  'sessionManagerService',
  'branchFilterService',
  'NgrxStore',
  'WebsocketService',
  async function (
    $scope,
    $rootScope,
    $localStorage,
    $modal,
    $timeout,
    calendarJobManagerService,
    AlertService,
    calendarProviderManagerService,
    EnterpriseFilterService,
    $window,
    FormatHelperService,
    AuthenticationService,
    trackingService,
    generalDataService,
    OverlapService,
    scheduleTotalWorkersService,
    TimeFormatService,
    PERMISSIONS,
    ForceFilterService,
    $location,
    sessionManagerService,
    branchFilterService,
    NgrxStore,
    WebsocketService,
  ) {
    const CURRENT_USER = AuthenticationService.getCurrentUser();

    $scope.totalWorkersHours = [];
    $scope.pageTitle = 'Schedule';
    
    const path = $location.path();
    if (path && path.split('/').length) {
      $scope.jobOrderIdFilter = path?.split('/')[2];
    }

    // This url is coming form BOLD, check for ext_order_id as part of the URL
    const currentPath = $location.path();
    const queryParams = $location.search();
    if (queryParams && queryParams.ext_order_id && currentPath === '/schedule') {
      $scope.ext_order_id = queryParams.ext_order_id;
      $scope.fromBold = true;
      await branchFilterService.fetchBranches();
    } else {
      $scope.ext_order_id = undefined;
      $scope.fromBold = false;
    }

    $scope.viewSchedule = false;
    $scope.keyProfession = generalDataService.getKeyProfession();
    $scope.keySession = generalDataService.getKeySession();
    $scope.featureFilter = false;
    $scope.showNestedRow = !$scope.jobOrderIdFilter;
    $scope.toggleCancelText = 'Hide Canceled';
    $scope.toggleCancelClass = 'icon-hide-canceled';
    $scope.legendOpen = false;
    $scope.showTotalWorkers = true;

    // Permission Check
    function permissionCheck(tabPermission) {
      if (!tabPermission) {
        $window.location.reload();
      }
    }

    const scheduleTabPermission = CURRENT_USER.permissionCheck(37);
    permissionCheck(scheduleTabPermission);
    $scope.scheduleJobPermission = CURRENT_USER.permissionCheck(39);
    $scope.scheduleSessionPermission = CURRENT_USER.permissionCheck(4);
    $scope.nowSessionPermission = CURRENT_USER.permissionCheck(5);
    $scope.cancelPermission = CURRENT_USER.permissionCheck(PERMISSIONS.CANCEL);
    $scope.massCancelPermission = CURRENT_USER.permissionCheck(PERMISSIONS.MASS_CANCEL);
    
    $scope.updateSelectedDateText = function () {
      if ($scope.filters.dateFilter === 'week') {
        $scope.selectedDateText =
          moment($scope.startTime).format('MMM DD') + ' - ' + moment($scope.endTime).format('ll');
      } else {
        $scope.selectedDateText = moment($scope.startTime).format('ll');
      }
    };

    $scope.canceledToggle = function () {
      $scope.hideCanceled = !$scope.hideCanceled;
      if ($scope.hideCanceled) {
        $scope.toggleCancelText = 'Show Canceled';
        $scope.toggleCancelClass = 'icon-show-canceled';
        $localStorage.hideCanceled = true;
      } else {
        $scope.toggleCancelText = 'Hide Canceled';
        $scope.toggleCancelClass = 'icon-hide-canceled';
        $localStorage.hideCanceled = false;
      }
      trackingService.trackEvent(trackingService.CALENDAR, 'Canceled', $scope.hideCanceled ? 'hidden' : 'shown');
    };

    $scope.pauseToggle = function () {
      $scope.hidePaused = !$scope.hidePaused;
      $scope.filterPausedSessions();
      trackingService.trackEvent(trackingService.CALENDAR, 'Paused', $scope.hidePaused ? 'hidden' : 'shown');
    };

    $scope.filterPausedSessions = function () {
      if ($scope.filters.typeFilter === 'Providers' && $scope.providersArray) {
        $scope.providersArray.forEach((provider) => {
          provider.session_list.forEach((session) => {
            if (session.pause_job) {
              session.displayValue = !$scope.hidePaused;
            }
          });
        });
      }
      if ($scope.filters.typeFilter === 'Jobs' && $scope.jobsArray) {
        $scope.jobsArray.forEach((job) => {
          job.session_list.forEach((session) => {
            if (session.pause_job) {
              session.displayValue = !$scope.hidePaused;
            }
          });
        });
      }
    };

    $scope.legendToggle = function () {
      $scope.legendOpen = !$scope.legendOpen;
      trackingService.trackEvent(trackingService.CALENDAR, 'Legend', $scope.legendOpen ? 'opened' : 'closed');
    };

    $scope.checkForApply = function () {
      sessionManagerService.getRealmAttribs(enums.Realms.Service).then(function (res) {
        $scope.applyPresent = res.attrib_list && res.attrib_list.find((a) => a.name === 'interest_flag');
      });
    };

    $scope.checkForApply();

    $scope.getJobOrderDetails = async function (ext_id) {
      try {
        const response = await sessionManagerService.getSingleJobOrderById(ext_id);
        let jobOrderDetails;

        if (response && response.success) {
          jobOrderDetails = response.job;
          $scope.hideCanceled = false;

          branchFilterService.setSelectedBranch(jobOrderDetails.branch_id);

          const result = await rxjs.firstValueFrom(
            NgrxStore.select((x) => x.commonData.enterpriseList).pipe(operators.filter((x) => !!x))
          );
          const selectedEnt = result.filter((ent) => ent.id === parseInt(jobOrderDetails.enterprise_id));
          if (selectedEnt.length > 0) {
            $scope.entFilter = selectedEnt[0];
            EnterpriseFilterService.setSelectedEnterprise($scope.entFilter);
          } else {
            AlertService.errorAlert('Unable to retrieve Enterprise');
            return;
          }

          if ($scope.entFilter) {
            try {
              if ($scope.entFilter.enterprise_id !== -1) {
                WebsocketService.connectToEnterprise($scope.entFilter.enterprise_id);
              } else {
                WebsocketService.closeSocket();
              }
            } catch (e) { }
          }
          const storedState = sessionStorage.getItem('calendarState');
          let parsed = {};
          if (storedState) {
            parsed = JSON.parse(storedState);
          }
          let startTime = moment
            .utc(jobOrderDetails.job_start_date, TimeFormatService.format('fullDate'))
            .startOf('week')
            .add(1, 'd');
          let endTime = moment
            .utc(jobOrderDetails.job_start_date, TimeFormatService.format('fullDate'))
            .endOf('week')
            .add(1, 'd');
          const endOfWeekTime = moment().endOf('week');
          if (!endTime.isSameOrAfter(endOfWeekTime)) {
            startTime = moment().startOf('week');
            endTime = endOfWeekTime;
          }
          $scope.filters = {
            dateFilter: 'week',
            typeFilter: 'Jobs',
          };
          parsed = {
            ...parsed,
            filters: $scope.filters,
            startTime: startTime.format(TimeFormatService.format('api')),
            endTime: endTime.format(TimeFormatService.format('api'))
          };
          sessionStorage.setItem('calendarState', JSON.stringify(parsed));
        } else {
          AlertService.serverRequestErrorAlert(response.message);
        }
      } catch (e) {
        AlertService.serverRequestErrorAlert('No Such Job Order Found with the ID');
      }
    };

    async function setIntitalScopes() {
      // if Navigating from Bold UI
      if ($scope.ext_order_id) {
        $scope.showSpinner = true;
        await $scope.getJobOrderDetails($scope.ext_order_id);
        $scope.showSpinner = false;
      }

      function setDefaultState() {
        $scope.filters = {
          dateFilter: 'day',
          typeFilter: $localStorage.calendarFilter || 'Providers',
        };
        $scope.startTime = moment().startOf('day').format(TimeFormatService.format('api'));
        $scope.endTime = moment().endOf('day').format(TimeFormatService.format('api'));
      }
      const storedState = sessionStorage.getItem('calendarState');
      let parsed;
      if (storedState) {
        try {
          parsed = JSON.parse(storedState);
          $scope.filters = parsed.filters;
          const isWeekFilter = $scope.filters.dateFilter !== 'day';
          $scope.startTime = isWeekFilter ? moment(parsed.startTime).startOf('week') : parsed.startTime;
          $scope.endTime = isWeekFilter ? moment(parsed.startTime).endOf('week') : parsed.endTime;
        } catch (e) {
          setDefaultState();
        }
      } else {
        setDefaultState();
      }

      if ($localStorage.hideCanceled) {
        $scope.canceledToggle();
      }
      // by default hide paused sessions
      $scope.pauseToggle();
    }

    $scope.storeState = function () {
      const state = {
        filters: $scope.filters,
        startTime: $scope.startTime,
        endTime: $scope.endTime,
      };
      sessionStorage.setItem('calendarState', JSON.stringify(state));
    };
    setIntitalScopes();

    $scope.filterToggle = function () {
      if ($scope.featureFilter == false) {
        $scope.featureFilter = true;
        $scope.applyFilters();
      } else {
        $scope.filterReset();
        $scope.featureFilter = false;
      }
      trackingService.trackEvent(trackingService.CALENDAR, 'Filter', $scope.featureFilter ? 'opened' : 'closed');
    };

    // Sorting methods
    function sortByTotalDuration(a, b) {
      const val = FormatHelperService.returnSortedValue(parseFloat(a.durationTotal), parseFloat(b.durationTotal));
      if (val === 0) {
        return sortBySessionListLength(a, b);
      } else {
        return val;
      }
    }
    function sortBySessionListLength(a, b) {
      return FormatHelperService.returnSortedValue(
        parseFloat(a.session_list.length),
        parseFloat(b.session_list.length)
      );
    }

    function sessionBorderChange(requestId) {
      const sessionID = '#' + requestId;
      $timeout(function () {
        $(sessionID).addClass('session-updated');
        $timeout(function () {
          $(sessionID).removeClass('session-updated');
        }, 10000);
      }, 300);
    }

    $scope.changeMainFilter = function (startTime, endTime) {
      trackingService.trackEvent(trackingService.CALENDAR, 'Main filter', $scope.filters.typeFilter);
      $scope.loadCalendarData(startTime, endTime);
    };

    $scope.toggleShowTotalWorkers = function () {
      $scope.showTotalWorkers = !$scope.showTotalWorkers;
    };

    function updateTotalWorkersAndHours() {
      const data = {
        startTime: $scope.startTime,
        dateFilter: $scope.filters.dateFilter,
        typeFilter: $scope.filters.typeFilter,
        filterList: $scope.filterList,
        searchTerm: $scope.search,
      };

      if ($scope.filters.typeFilter === 'Providers' && $scope.providersArray) {
        data.data = $scope.providersArray;
        $scope.totalWorkersHours = scheduleTotalWorkersService.filteredTotalWorkersHours(data);
      } else if ($scope.jobsArray) {
        data.data = $scope.jobsArray;
        $scope.totalWorkersHours = scheduleTotalWorkersService.filteredTotalWorkersHours(data);
      }
    }

    $scope.onSearchChanged = function (search) {
      $scope.search = search;
      updateTotalWorkersAndHours();
    };

    $scope.loadCalendarData = function (startTime, endTime, possibleSessionId) {
      $scope.showSpinner = true;
       
      $scope.entFilter = EnterpriseFilterService.fetchSelectedEnterprise();
      $scope.load_all_jobs = CURRENT_USER.market_place_info ? CURRENT_USER.market_place_info.load_all_jobs : false;

      if ($scope.filters.typeFilter === 'Providers') {
        calendarProviderManagerService
          .getAllCalProviders(startTime, endTime, $scope.entFilter.enterprise_id, $scope.filters.dateFilter)
          .then(
            function (calProvidersArray) {
              $scope.startTime = startTime;
              $scope.providersArray = calProvidersArray;
              updateTotalWorkersAndHours();

              $scope.providersArray.sort(sortByTotalDuration);
              $scope.showSpinner = false;
              possibleSessionId && sessionBorderChange(possibleSessionId);
              $scope.updateSelectedDateText();
              $scope.filterPausedSessions();
            },
            function (reason) {
              AlertService.serverRequestErrorAlert(reason);
              $scope.showSpinner = false;
            }
          );
      } else {
        calendarJobManagerService.getAllCalJobs(startTime, endTime, $scope.entFilter.enterprise_id).then(
          function (calJobsArray) {
            $scope.jobsArray = calJobsArray;
            $scope.startTime = startTime;

            if ($scope.jobOrderIdFilter) {
              $scope.jobsArray = calJobsArray.filter(
                (j) => parseInt(j.jobOrderId) === parseInt($scope.jobOrderIdFilter)
              );
            }
            if ($scope.fromBold && $scope.ext_order_id) {
              $scope.jobsArray = calJobsArray.filter(
                (j) => parseInt(j.ext_order_id) === parseInt($scope.ext_order_id)
              );
            }

            updateTotalWorkersAndHours();
            //            $scope.jobsArray.sort(sortByFirstSession);
            providerDisplayCheck($scope.jobsArray);
            $scope.filterPausedSessions();
            $scope.showSpinner = false;
            possibleSessionId && sessionBorderChange(possibleSessionId);
            $scope.updateSelectedDateText();
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
            $scope.showSpinner = false;
          }
        );
      }
      $localStorage.calendarFilter = $scope.filters.typeFilter;
      $scope.updateSelectedDateText();
      $scope.storeState();
      $scope.$broadcast('dateChangeOccurred');
    };

    $rootScope.$on('changeJobOrderSuccess', function () {
      $scope.loadCalendarData($scope.startTime, $scope.endTime);
    });

    // provider list display boolean check/set
    function providerDisplayCheck(jobsArray) {
      angular.forEach(jobsArray, function (job) {
        job.setProviderListDisplayBoolean();
      });
    }

    $scope.beforeRenderCalendar = function ($view, $dates) {
      const timeUnit = $scope.filters.dateFilter === 'week' ? 'week' : 'day';
      if ($view === 'day') {
        $dates.forEach(function (date) {
          const calendarLocalDate = moment(date.localDateValue()).startOf('day');
          if (moment($scope.startTime).isSame(calendarLocalDate, timeUnit)) {
            date.active = true;
          } else {
            date.active = false;
          }
        });
      }
    };

    $scope.updateDateFilter = function (setToToday) {
      const startDate = setToToday ? moment() : moment($scope.startTime);
      if ($scope.filters.dateFilter === 'week') {
        $scope.startTime = startDate.startOf('week').format(TimeFormatService.format('api'));
        $scope.endTime = startDate.endOf('week').format(TimeFormatService.format('api'));
        $('.datetimepicker').addClass('weekly');
      } else {
        $scope.startTime = startDate.startOf('day').format(TimeFormatService.format('api'));
        $scope.endTime = startDate.endOf('day').format(TimeFormatService.format('api'));
        $('.datetimepicker').removeClass('weekly');
      }
      $scope.loadCalendarData($scope.startTime, $scope.endTime);
      if (setToToday) {
        trackingService.trackEvent(trackingService.CALENDAR, 'DateSelection', 'today');
      } else {
        trackingService.trackEvent(trackingService.CALENDAR, 'DatePeriod', $scope.filters.dateFilter);
      }
    };

    $scope.openCalendar = function () {
      $scope.isCalendarOpened = !$scope.isCalendarOpened;
    };

    $scope.selectDate = function (date) {
      $scope.isCalendarOpened = false;

      if ($scope.filters.dateFilter === 'week') {
        $scope.startTime = moment(date).startOf('week').format(TimeFormatService.format('api'));
        $scope.endTime = moment($scope.startTime).endOf('week').format(TimeFormatService.format('api'));
      } else {
        $scope.startTime = moment(date).format(TimeFormatService.format('api'));
        $scope.endTime = moment($scope.startTime).endOf('d').format(TimeFormatService.format('api'));
      }
      $scope.loadCalendarData($scope.startTime, $scope.endTime);
      trackingService.trackEvent(trackingService.CALENDAR, 'DateSelection', 'calendar');
    };

    $scope.nextTimePeriod = function () {
      if (!$scope.showSpinner) {
        if ($scope.filters.dateFilter === 'week') {
          $scope.startTime = moment($scope.startTime, TimeFormatService.format('api'))
            .add(1, 'w')
            .format(TimeFormatService.format('api'));
          $scope.endTime = moment($scope.endTime, TimeFormatService.format('api'))
            .add(1, 'w')
            .format(TimeFormatService.format('api'));
        } else {
          $scope.startTime = moment($scope.startTime, TimeFormatService.format('api'))
            .add(1, 'd')
            .format(TimeFormatService.format('api'));
          $scope.endTime = moment($scope.startTime, TimeFormatService.format('api'))
            .endOf('d')
            .format(TimeFormatService.format('api'));
        }
        $scope.loadCalendarData($scope.startTime, $scope.endTime);
        trackingService.trackEvent(trackingService.CALENDAR, 'DateSelection', 'next');
      }
    };

    $scope.previousTimePeriod = function () {
      if (!$scope.showSpinner) {
        if ($scope.filters.dateFilter === 'week') {
          $scope.startTime = moment($scope.startTime, TimeFormatService.format('api'))
            .subtract(1, 'w')
            .format(TimeFormatService.format('api'));
          $scope.endTime = moment($scope.endTime, TimeFormatService.format('api'))
            .subtract(1, 'w')
            .format(TimeFormatService.format('api'));
        } else {
          $scope.startTime = moment($scope.startTime, TimeFormatService.format('api'))
            .subtract(1, 'd')
            .format(TimeFormatService.format('api'));
          $scope.endTime = moment($scope.startTime, TimeFormatService.format('api'))
            .endOf('d')
            .format(TimeFormatService.format('api'));
        }
        $scope.loadCalendarData($scope.startTime, $scope.endTime);
        trackingService.trackEvent(trackingService.CALENDAR, 'DateSelection', 'previous');
      }
    };

    $scope.popover = {
      legendPopover: 'app/views/templates/calendar_legend.html',
    };

    // Open session/joh Modal trigger
    $scope.openNowSessionModal = function () {
      trackingService.trackEvent(trackingService.CALENDAR, 'Schedule', 'now session');
      const modalInstance = $modal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/views/session/requestSessionModal.html',
        controller: 'NowJobModalController',
        keyboard: false,
        size: 'lg',
        backdrop: 'static',
        resolve: {
          enterprise_selection: function () {
            return $scope.entFilter;
          },
          passed_job_order_id: function () {
            return $scope.entFilter;
          },
        },
      });
      modalInstance.result.then(function () {});
    };

    $scope.openScheduleSessionModal = function () {
      trackingService.trackEvent(trackingService.CALENDAR, 'Schedule', 'schedule session');
      const modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/session/requestSessionModal.html',
        controller: 'RequestScheduleJobModalController',
        keyboard: false,
        size: 'lg',
        backdrop: 'static',
        resolve: {
          enterprise_selection: function () {
            return $scope.entFilter;
          },
          passedSession: function () {
            return null;
          },
          start_time: function () {
            return null;
          },
          passedJobOrderDetails: function () {
            return null;
          },
          passed_current_worker_count: function () {
            return null;
          },
          passed_total_worker_count: function () {
            return null;
          },
          passed_job_order_id: function () {
            return null;
          },
        },
      });

      modalInstance.result.then(function () {});
    };

    $scope.openScheduleJobModal = function () {
      trackingService.trackEvent(trackingService.CALENDAR, 'Schedule', 'schedule job');
      const modalInstance = $modal.open({
        animation: true,
        templateUrl: 'app/views/schedule/scheduleJobModal.html',
        controller: 'NewScheduleJobModalController',
        keyboard: false,
        size: 'lg',
        backdrop: 'static',
        resolve: {
          enterprise_selection: function () {
            return $scope.entFilter;
          },
          passed_job_id: function () {
            return null;
          },
          passed_job_order_id: function () {
            return null;
          },
          passedSession: function () {
            return null;
          },
          start_time: function () {
            return null;
          },
          passedJobOrderDetails: function () {
            return null;
          },
          passed_current_worker_count: function () {
            return null;
          },
          passed_total_worker_count: function () {
            return null;
          },
        },
      });

      modalInstance.result.then(function () {});
    };

    // Dynamic filters on session attributes
    function getFilters() {
      sessionManagerService.getRealmAttribs(enums.Realms.JobFilter).then(
        function (response) {
          const filterAttribs = response.attrib_list.sort(function (a, b) {
            return a.order - b.order;
          });
          $scope.filterAttribs = angular.copy(filterAttribs);
          $scope.originalFilterAttribs = angular.copy(filterAttribs);
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
        }
      );
    }

    $scope.applyFilters = function () {
      trackingService.trackEvent(trackingService.CALENDAR, 'Filters', 'apply');
      $scope.filterList = FormatHelperService.format_data($scope.filterAttribs);
      // Remove multi list filters if their value is an empty array (no list item selected)
      $scope.filterList = $scope.filterList.filter((x) => !x.value_multi_list || x.value_multi_list.length);
      updateTotalWorkersAndHours();
    };

    $scope.filterReset = function () {
      trackingService.trackEvent(trackingService.CALENDAR, 'Filters', 'reset');
      delete $scope.filterList;
      $scope.filterAttribs = angular.copy($scope.originalFilterAttribs);

      updateTotalWorkersAndHours();
    };

    getFilters();

    $scope.$on('dayViewClicked', function (event, startTime, endTime) {
      $scope.filters.dateFilter = 'day';
      $scope.startTime = startTime;
      $scope.endTime = endTime;
      $scope.loadCalendarData(startTime, endTime);
      event.stopPropagation();
    });

    // check to see if current calendar display view is affected by message request time
    function displayViewCheck(newMessage) {
      let _utcRequestTime, _localRequestStartTime, _momentViewStartTime, _momentViewEndTime;

      _utcRequestTime = newMessage.request_date + ' ' + newMessage.request_time;
      _localRequestStartTime = moment.utc(_utcRequestTime, 'MM/DD/YYYY HH:mm:ss').local().clone();
      _momentViewStartTime = moment($scope.startTime, TimeFormatService.format('api')).subtract(1, 'd').clone();
      _momentViewEndTime = moment($scope.endTime, TimeFormatService.format('api')).clone();

      if (
        _localRequestStartTime.isBetween(_momentViewStartTime, _momentViewEndTime) ||
        _localRequestStartTime.isSame(_momentViewStartTime)
      ) {
        return true;
      } else {
        return false;
      }
    }

    // Grab the selected enterprise when first come to schedule section
    function setDisplay() {
      if ($scope.entFilter.id == -1) {
        $scope.viewSchedule = false;
      } else {
        ForceFilterService.fetchRequiredAttribs().then((requiredAttribs) => {
          if (requiredAttribs?.length && !ForceFilterService.getFilter()?.length) {
            $scope.viewSchedule = false;
          } else {
            $scope.showSpinner = true;
            $scope.viewSchedule = true;
            $scope.loadCalendarData($scope.startTime, $scope.endTime);
          }
        });
      }
    }

    function userTypeCheck() {
      if (CURRENT_USER.user_type == 2 || CURRENT_USER.user_type == 6) {
        // Marketplace user
        $scope.viewSchedule = false;
        $scope.scheduleActions = true;
        // Grab the selected enterprise when filter changes states
        $scope.$watch('entFilter', function (newVal, oldVal) {
          $scope.entFilter = EnterpriseFilterService.fetchSelectedEnterprise();
          $scope.entFilter.enterprise_id && setDisplay();
          $scope.checkForApply();
        });
      } else {
        $scope.scheduleActions = true;
        $scope.entFilter = EnterpriseFilterService.fetchSelectedEnterprise();
        setDisplay();
      }
      $scope.$on('adminFilter', function () {
        $scope.entFilter.enterprise_id && setDisplay();
      });
    }
    userTypeCheck();

    $scope.updateSessionWithArgs = function (session, args) {
      session.job_name = args.job_name;
      session.job_id = args.job_id;
      session.bundled = args.bundled;
      session.apply = session.status.type === 8 || session.status.type === 9;
      session.total_worker_count = args.total_worker_count;
      session.number_candidates = args.number_candidates;
      session.committed_worker_count = args.committed_worker_count;
      session.current_workers = args.current_workers || args.committed_worker_count;
      session.jobOrderId = args.job_order_id;
      session.job_order_id = args.job_order_id;
      session.bundled_job_id = args.job_id;
      session.job_instance = args.job_instance;
      session.job_map = args.job_map;
      session.job_length = args.session_list.length;
    };

    // WebSocket
    $scope.$on('NOTIFICATION_UPDATE', function (event, args) {
      switch (args.action) {
        case 'NEW_REQUEST':
          $scope.addNotificationRequest(args);
          break;
        case 'ADMIN_CANCEL_REQUEST':
        case 'REQ_CANCEL_REQUEST':
        case 'PRO_CANCEL_REQUEST':
        case 'NEW_ASSIGNED_SCHEDULED_REQUEST':
        case 'CHANGE_ASSIGNED_REQUEST':
        case 'COMMITTED_REQUEST':
        case 'INTEREST_OFFER_REQUEST':
        case 'NO_INTEREST_REQUEST':
          $scope.removeRequest(args);
          $scope.addNotificationRequest(args);
          break;
        case 'ADMIN_PROVIDER_CHECKIN':
        case 'PROVIDER_CHECKIN':
        case 'ADMIN_PROVIDER_CHECKOUT':
          let checkInTime = moment.utc(args.checkin_date + ' ' + args.checkin_time).format();
          $scope.removeRequest(args);
          $scope.addNotificationRequest(args, checkInTime);
          break;
        case 'REQ_COMPLETE_REQUEST':
        // TODO: remove when fixed
        case 'EXPIRED_REQUEST':
          break;
        case 'NEW_SCHEDULED_JOB':
          if (OverlapService.hasOverlappingSessions(args.session_list)) {
            // 2593, do a force refresh for now
            $scope.loadCalendarData($scope.startTime, $scope.endTime, args.request_id);
          } else {
            args.session_list.forEach((session) => {
              $scope.updateSessionWithArgs(session, args);
              $scope.addNotificationRequest(session);
            });
          }
          break;
        case 'ADMIN_REMOVE_REQUEST':
        case 'ESCALATE_COMMITTED_REQUEST':
          $scope.removeRequest(args);
          break;
        case 'JOBLEVEL_CANCEL':
          args.session_list.forEach((session) => $scope.removeRequest(session));
          args.session_list.forEach((session) => {
            $scope.updateSessionWithArgs(session, args);
            $scope.addNotificationRequest(session);
          });
          break;
        case 'ADMIN_REMOVE_JOB':
          args.session_list.forEach((session) => $scope.removeRequest(session));
          break;
        case 'REQUEUE_CANCELLED_JOBORDER':
        case 'REQUEUE_COMMITTED_JOBORDER':
        case 'INTEREST_PERIOD_OVER_JOBORDER':
        case 'NO_INTEREST_JOBORDER':
          args.session_list.forEach((session) => $scope.removeRequest(session));
          args.session_list.forEach((session) => {
            $scope.updateSessionWithArgs(session, args);
            $scope.addNotificationRequest(session);
          });
          if (args.bundled) {
            $scope.updateBundledCounter(args, 'Pending');
          }
          break;
        case 'COMMITTED_JOBORDER':
          if (args.committed_worker_count === args.total_worker_count) {
            $scope.removeBundledJob(args);
          } else {
            $scope.updateBundledCounter(args, 'Pending');
          }
          args.session_list.forEach((session) => {
            $scope.updateSessionWithArgs(session, args);
            $scope.addNotificationRequest(session);
          });
          break;

        case 'NEW_OPEN_JOB':
        case 'NEW_ASSIGNED_JOB':
          $scope.loadCalendarData($scope.startTime, $scope.endTime, args.request_id);
          break;
        case 'JOBLEVEL_WORKERCOUNT_UPDATE':
          $scope.updateBundledCounter(args, 'Pending');
          break;
        case 'NEW_INTEREST_REQUEST':
        case 'NEW_INTEREST_JOBORDER':
        case 'REMOVE_INTEREST_REQUEST':
        case 'REMOVE_INTEREST_JOBORDER':
          $scope.updateBundledCounter(args, 'Interest');
          break;
        case 'JOBLEVEL_WORKERCOUNT_COMPLETE':
          $scope.removeBundledJob(args);
          break;
        default:
          if (args.session_list) {
            if (args.session_list.some(displayViewCheck)) {
              $scope.loadCalendarData($scope.startTime, $scope.endTime, args.request_id);
            }
          } else if (displayViewCheck(args)) {
            $scope.loadCalendarData($scope.startTime, $scope.endTime, args.request_id);
          } else if (args.forceUpdate) {
            $scope.loadCalendarData($scope.startTime, $scope.endTime);
          }
          break;
      }
    });

    $scope.removeBundledJob = function (args) {
      if ($scope.filters.typeFilter === 'Providers') {
        const pending = $scope.providersArray.find((x) => x.user_id === 'Pending');
        if (pending) {
          const targetJob = pending.job_list.findIndex((x) => x.jobOrderId === args.job_order_id);
          if (targetJob > -1) {
            pending.job_list.splice(targetJob, 1);
            pending.session_list
              .filter((x) => x.jobOrderId === args.job_order_id)
              .forEach((request) => {
                pending.session_list.splice(pending.session_list.indexOf(request), 1);
              });
          }
        }
      } else {
        const targetJob = $scope.jobsArray.findIndex(
          (x) => x.jobOrderId === args.job_order_id && x.job_map === args.job_map
        );
        if (targetJob > -1) {
          $scope.jobsArray.splice(targetJob, 1);
        }
      }
    };

    $scope.updateBundledCounter = function (args, stateName) {
      if ($scope.filters.typeFilter === 'Providers') {
        const pending = $scope.providersArray.find((x) => x.user_id === stateName);
        if (pending) {
          const targetJob = pending.job_list.find((x) => x.jobOrderId === args.job_order_id);
          if (targetJob) {
            let jobLength, duration, totalWorkers, currentWorkers;
            targetJob.total_worker_count = args.total_worker_count;
            targetJob.total_workers = args.total_worker_count;
            targetJob.committed_worker_count = args.committed_worker_count;
            targetJob.current_workers = args.committed_worker_count;
            targetJob.number_candidates = args.number_candidates;

            jobLength = targetJob.session_list.length;
            totalWorkers = args.total_worker_count;
            currentWorkers = args.committed_worker_count;

            targetJob.session_list.forEach((session) => {
              session.total_worker_count = args.total_worker_count;
              session.total_workers = args.total_worker_count;
              session.committed_worker_count = args.committed_worker_count;
              session.current_workers = args.committed_worker_count;
              session.number_candidates = args.number_candidates;

              duration = session.duration;
            });

            if (duration && stateName !== 'Interest') {
              targetJob.durationTotal = jobLength * duration * (totalWorkers - currentWorkers);
            }
          }
        }
      } else {
        const targetJob = $scope.jobsArray.find((x) => x.jobOrderId === args.job_order_id);
        if (targetJob) {
          let jobLength, duration, totalWorkers, currentWorkers;

          targetJob.total_worker_count = args.total_worker_count;
          targetJob.total_workers = args.total_worker_count;
          targetJob.committed_worker_count = args.committed_worker_count;
          targetJob.current_workers = args.committed_worker_count;
          targetJob.number_candidates = args.number_candidates;

          jobLength = targetJob.session_list.length;
          totalWorkers = args.total_worker_count;
          currentWorkers = args.committed_worker_count;

          targetJob.session_list.forEach((session) => {
            session.total_worker_count = args.total_worker_count;
            session.total_workers = args.total_worker_count;
            session.committed_worker_count = args.committed_worker_count;
            session.current_workers = args.committed_worker_count;
            session.number_candidates = args.number_candidates;

            duration = session.duration;
          });

          if (duration && stateName !== 'Interest') {
            targetJob.durationTotal = jobLength * duration * (totalWorkers - currentWorkers);
          }
        }
      }
    };

    $scope.handleSpecialProviderDuration = function () {
      $scope.providersArray.forEach((provider) => {
        if (isNaN(provider.user_id)) {
          delete provider.durationTotal;
        }
      });
    };

    $scope.removeProviderRequest = function (request) {
      const targetProvider = $scope.providersArray.find((x) =>
        x.session_list.find((s) => s.session_id === request.request_id)
      );
      if (targetProvider) {
        const targetSession = targetProvider.session_list.find((x) => x.session_id === request.request_id);
        if (targetSession) {
          const index = targetProvider.session_list.indexOf(targetSession);
          targetProvider.session_list.splice(index, 1);
          targetProvider.durationTotal -= targetSession.duration;
        }
        const targetJob = targetProvider.job_list.find((job) =>
          job.session_list.find((session) => session.session_id === request.request_id)
        );
        if (targetJob) {
          const targetSessionIndex = targetJob.session_list.findIndex(
            (session) => session.session_id === request.request_id
          );
          if (targetSessionIndex !== -1) {
            targetJob.session_list.splice(targetSessionIndex, 1);
            targetJob.durationTotal -= targetSession.duration;
            if (!targetJob.session_list.length) {
              const index = targetProvider.job_list.indexOf(targetJob);
              targetProvider.job_list.splice(index, 1);
            }
          }
        }
        const overlaps = targetProvider.session_list.filter(
          (x) => x.overlap_id && x.session_ids.indexOf(targetSession.session_id) !== -1
        );
        overlaps.forEach((x) => {
          const overlapIndex = targetProvider.session_list.indexOf(x);
          targetProvider.session_list.splice(overlapIndex, 1);
        });
      }
    };

    $scope.removeJobRequest = function (request) {
      const targetJob = $scope.jobsArray.find((job) =>
        job.session_list.find((session) => session.session_id === request.request_id)
      );
      if (targetJob) {
        const targetSession = targetJob.session_list.find((session) => session.session_id === request.request_id);
        if (targetSession) {
          const index = targetJob.session_list.indexOf(targetSession);
          targetJob.session_list.splice(index, 1);
          targetJob.durationTotal -= targetSession.duration;

          if (!targetJob.length) {
            const index = $scope.jobsArray.indexOf(targetJob);
            $scope.jobsArray.splice(index, 1);
          }
        }
      }
    };

    $scope.removeRequest = function (request) {
      if (displayViewCheck(request)) {
        if ($scope.filters.typeFilter === 'Providers') {
          $scope.removeProviderRequest(request);
        } else {
          $scope.removeJobRequest(request);
        }
      }
    };

    $scope.buildNotificationFromPayload = function (request, atTime) {
      const localStart = atTime || moment.utc(request.request_date + ' ' + request.request_time).format();
      let duration = request.duration;
      if (request.status.type === 5) {
        duration = request.request_total_time;
      }
      return {
        attrib_list: request.attrib_list,
        cancel_code: request.cancelled_reason,
        child: false,
        committed: 0,
        displayValue: true,
        duration: duration,
        end: moment(localStart).add(duration, 'm').format(TimeFormatService.format('fullDateTimeSecs')),
        request_type: request.type,
        session_id: request.request_id,
        staff: true,
        job_id: request.job_id,
        start: moment(localStart),
        start_time: moment(localStart).format(TimeFormatService.format('fullDateTimeSecs')),
        state: request.status,
        user_id: request.serv_user_id || request.status.name,
        job_name: request.job_name,
        request_date: request.request_date,
        request_time: request.request_time,
        bundled: request.bundled,
        total_worker_count: request.total_worker_count,
        number_candidates: request.number_candidates,
        committed_worker_count: request.committed_worker_count,
        current_workers: request.current_workers,
        jobOrderId: request.job_order_id,
        job_order_id: request.job_order_id,
        bundled_job_id: request.job_id,
        job_instance: request.job_instance,
        job_map: request.job_map,
        provider_name: request.serv_user_id
          ? `${request.serv_first_name} ${request.serv_last_name}`
          : request.status.name,
        job_length: request.job_length,
        apply: request.status.type === 8 || request.status.type === 9,
      };
    };

    $scope.addProviderRequest = function (newRequest, atTime) {
      let targetProvider = $scope.providersArray.find((x) => x.user_id === newRequest.user_id);
      const fixedPosition = {
        Open: 5,
        Interest: 4,
        'Waiting Decision': 3,
        Pending: 2,
        Canceled: 1,
      };
      if (!targetProvider) {
        targetProvider = {
          name: newRequest.provider_name,
          durationTotal: 0,
          user_id: newRequest.user_id,
          isFixed: isNaN(newRequest.user_id) ? fixedPosition[newRequest.user_id] : false,
          session_list: [],
          job_list: [],
          displayValue: true,
        };
        $scope.providersArray.push(targetProvider);
      }
      targetProvider.session_list.push(newRequest);
      if (newRequest.state.type !== 7) {
        targetProvider.durationTotal += newRequest.duration;
      }

      const jobRequest = angular.copy(newRequest);
      jobRequest.child = true;
      jobRequest.association = true;

      let newJob;
      if (jobRequest.job_id) {
        newJob = targetProvider.job_list.find(
          (job) => job.job_id === jobRequest.job_id && job.job_instance === jobRequest.job_instance
        );
      }
      if (!newJob) {
        newJob = {
          session_list: [jobRequest],
          displayValue: true,
          durationTotal: jobRequest.duration,
          job_id: jobRequest.job_id || jobRequest.session_id,
          job_instance: jobRequest.job_instance,
          job_map: jobRequest.job_map,
          name: jobRequest.job_name,
          bundled: jobRequest.bundled,
          committed_worker_count: jobRequest.committed_worker_count,
          total_worker_count: jobRequest.total_worker_count,
          number_candidates: jobRequest.number_candidates,
          job_order_id: jobRequest.jobOrderId,
          jobOrderId: jobRequest.jobOrderId,
          current_workers: jobRequest.committed_worker_count,
          total_workers: jobRequest.total_worker_count,
          apply: jobRequest.apply,
        };
        targetProvider.job_list.push(newJob);
      } else {
        newJob.session_list.push(jobRequest);
      }

      if (newJob.bundled && jobRequest.user_id === 'Pending') {
        newJob.durationTotal =
          jobRequest.job_length * jobRequest.duration * (newJob.total_workers - newJob.current_workers);
      } else if (newJob.bundled) {
        newJob.durationTotal = jobRequest.duration * (newJob.total_workers - newJob.current_workers);
      } else if (!jobRequest.state.type === 7) {
        newJob.durationTotal += jobRequest.duration;
      }
      $scope.handleSpecialProviderDuration();
      $scope.providersArray.sort(sortByTotalDuration);
      OverlapService.buildOverlapSesisons(targetProvider.session_list);
    };

    $scope.addJobRequest = function (newRequest, atTime) {
      const providerRequest = angular.copy(newRequest);
      providerRequest.child = true;
      providerRequest.association = true;
      const newProvider = {
        name: newRequest.provider_name,
        durationTotal: newRequest.state.type === 7 ? 0 : newRequest.duration,
        user_id: newRequest.serv_user_id,
        session_list: [providerRequest],
        displayValue: true,
      };

      let newJob = $scope.jobsArray.find(
        (job) =>
          job.job_id === newRequest.job_id &&
          job.job_instance === newRequest.job_instance &&
          job.job_map === newRequest.job_map
      );
      if (!newJob) {
        newJob = {
          session_list: [newRequest],
          displayValue: true,
          durationTotal: newRequest.state.type === 7 ? 0 : newRequest.duration,
          job_id: newRequest.job_id,
          job_instance: newRequest.job_instance,
          job_map: newRequest.job_map,
          name: newRequest.job_name,
          provider_list: !isNaN(newProvider.user_id) ? [newProvider] : [],
          displayProviderList: true,
          bundled: newRequest.bundled,
          committed_worker_count: newRequest.committed_worker_count,
          total_worker_count: newRequest.total_worker_count,
          number_candidates: newRequest.number_candidates,
          job_order_id: newRequest.jobOrderId,
          jobOrderId: newRequest.jobOrderId,
          current_workers: newRequest.committed_worker_count,
          total_workers: newRequest.total_worker_count,
          showBundled: newRequest.provider_name === 'Pending' || newRequest.provider_name === 'Canceled',
          apply: newRequest.apply,
          showApply: newRequest.provider_name === 'Interest' || newRequest.provider_name === 'Waiting Decision',
        };
        if (newJob.showBundled) {
          newJob.durationTotal =
            newRequest.job_length *
            newRequest.duration *
            (newRequest.total_worker_count - newRequest.committed_worker_count);
        }
        $scope.jobsArray.push(newJob);
      } else {
        newJob.session_list.push(newRequest);
      }

      $scope.jobsArray.sort(sortByTotalDuration);
    };

    $scope.addNotificationRequest = function (request, atTime) {
      if (displayViewCheck(request)) {
        const newRequest = $scope.buildNotificationFromPayload(request, atTime);
        if ($scope.filters.typeFilter === 'Providers') {
          $scope.addProviderRequest(newRequest, atTime);
        } else {
          $scope.addJobRequest(newRequest, atTime);
        }
        sessionBorderChange(newRequest.session_id);
      }
    };

    $scope.$on('WorkWeekStart.Change', () => {
      if ($scope.filters.dateFilter !== 'day') {
        $scope.startTime = moment($scope.startTime).startOf('week');
        $scope.endTime = moment($scope.startTime).endOf('week');
      }
    });

    $scope.openMassCancel = () => {
      const data = $scope.filters.typeFilter === 'Providers' ? $scope.providersArray : $scope.jobsArray;
      $modal.open({
        animation: true,
        templateUrl: 'app/views/job/massCancelModal.html',
        controller: 'MassCancelModalController',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          passedData: function () {
            return {
              date: new Date(),
              sessionData: [...data],
              filterType: $scope.filters.typeFilter,
            };
          },
        },
      });
    };

    $scope.returnToJobOrder = () => {
      location.href = '/#/job-order-report';
    };
  },
]);
