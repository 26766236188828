import $ from 'jquery';
import module from '../module';
import Swal from 'sweetalert2';
import moment from 'moment';
import * as enums from '../../enums';

export default module.controller('JobTabController', [
  '$scope',
  '$rootScope',
  '$location',
  '$modal',
  '$localStorage',
  'jobManagerService',
  'AuthenticationService',
  'branchFilterService',
  'generalDataService',
  'EnterpriseFilterService',
  'sessionManagerService',
  'AlertService',
  'TimeFormatService',
  function (
    $scope,
    $rootScope,
    $location,
    $modal,
    $localStorage,
    jobManagerService,
    AuthenticationService,
    branchFilterService,
    generalDataService,
    EnterpriseFilterService,
    sessionManagerService,
    AlertService,
    TimeFormatService
  ) {
    $scope.key_session = generalDataService.getKeySession().toLowerCase();
    $scope.pageSize = 50;
    $scope.currentPage = 1;

    sessionStorage.removeItem('jobOrderIdFilter');
    let savedAttribFilters = null;
    let delayFetch;

    const CURRENT_USER = AuthenticationService.getCurrentUser();
    $scope.jobCrudPermission = CURRENT_USER.permissionCheck(38);
    $scope.customFilters = {
      cancelled: false,
    };

    let unbindBranchHandler = null;
    let unbindUpdateHandler = null;

    $scope.sortParams = {
      field_id: localStorage.getItem('sortParams.field_id') ? parseInt(localStorage.getItem('sortParams.field_id')) : 2,
      descending: localStorage.getItem('sortParams.descending')
        ? localStorage.getItem('sortParams.descending') === 'true'
          ? true
          : false
        : false,
    };

    delayFetch = setTimeout(() => $scope.getEnterprises, 1000);
    let wasBranchSelectionChanged = false;
    let firstSortParams = true;

    const unwatchSortParams = $scope.$watch(
      'sortParams',
      function (newV) {
        localStorage.setItem('sortParams.field_id', newV.field_id);
        localStorage.setItem('sortParams.descending', newV.descending);
        if (firstSortParams) {
          firstSortParams = false;
          return;
        }
        $scope.getReports();
      },
      true
    );

    const unwatchEntFilter = $scope.$watch('entFilter', () => {
      $scope.getReports();
    });

    const unwatchBranchFilter = $scope.$watch('branchFilter', () => {
      $scope.getReports();
      wasBranchSelectionChanged = false;
    });

    unbindBranchHandler = $rootScope.$on('BranchSelectionChanged', function () {
      wasBranchSelectionChanged = true;
    });

    unbindUpdateHandler = $rootScope.$on('UpdateJobsTab', function () {
      $scope.getReports();
    });

    $scope.customColumnSettings = {
      orderIdColumn: 'job_order_id',
      startDateColumn: 'job_start_date',
    };

    $scope.closePopups = function (event) {
      if (event.target && !$(event.target).hasClass('popover-trigger')) {
        $('div.popover').remove();
      }
    };

    $scope.filterReports = function (searchTerm) {
      // TODO excluded columns
      const pieces = searchTerm.toLowerCase().split(' ');
      const containsTerm = (row) =>
        Object.keys(row)
          .map((k) => row[k])
          .filter((rowValue) => pieces.filter((p) => rowValue && rowValue.toString().toLowerCase().includes(p)).length)
          .length;

      if ($scope.jobs) {
        $scope.filteredJobs = $scope.jobs.filter((job) => containsTerm(job));
      }
    };

    $scope.gotoPage = function (page) {
      $scope.currentPage = page;
      $scope.getReports();
    };

    $scope.getReports = function () {
      $scope.showSpinner = true;
      const date = new Date();
      date.setDate(date.getDate() - 1);

      let asOfDateTime = date;

      const filterDate = savedAttribFilters?.find((x) => x.name === 'date');
      if (filterDate) {
        asOfDateTime = filterDate.value;
      }
      let params = {
        as_of_datetime: moment.utc(asOfDateTime).format(TimeFormatService.format('api')),
      };

      if ($scope.branchFilter && $scope.branchFilter.id && parseInt($scope.branchFilter.id) > 0) {
        params = {
          ...params,
          branch_id: $scope.branchFilter.id,
        };
      }

      if ($scope.entFilter && $scope.entFilter.enterprise_id && $scope.entFilter.enterprise_id > 0) {
        params = {
          ...params,
          enterprise_id: $scope.entFilter.enterprise_id,
        };
      }

      const keyAttribFilter = savedAttribFilters?.find((x) => x.name === $scope.keyAttrib.data_content);
      if (keyAttribFilter) {
        params = {
          ...params,
          key_attrib_filter: {
            attrib_id: keyAttribFilter.attrib_id,
            attrib_name: keyAttribFilter.name,
            value_single_list: keyAttribFilter.value.choice_id,
          },
        };
      }

      const pickListFilter = savedAttribFilters?.find((x) => x.name === 'picklist');
      if (pickListFilter) {
        params.picklist = pickListFilter.value?.choice_id ?? undefined;
      }

      const jobStatusFilter = savedAttribFilters?.find((x) => x.name === 'job_status');
      if (jobStatusFilter) {
        params.job_status = jobStatusFilter.value?.choice_id;
      }

      if ($scope.sortParams) {
        params = {
          ...params,
          order_by: $scope.sortParams,
        };
      }

      if ($scope.currentPage) {
        params = {
          ...params,
          page_number: $scope.currentPage,
          page_size: $scope.pageSize,
        };
      }

      jobManagerService
        .getJobReports(params)
        .then((response) => {
          if (response.total_count) {
            $scope.numberOfJobs = response.total_count;
          }
          $scope.showSpinner = false;

          $scope.filteredJobs = $scope.jobs = response.job_list.map((job) => ({
            ...job,
            job_start_date: job.job_start_date
              ? moment(job.job_start_date).format(TimeFormatService.format('shortDate'))
              : '',
            job_end_date: job.job_end_date
              ? moment(job.job_end_date).format(TimeFormatService.format('shortDate'))
              : '',
            next_shift: job.next_shift ? moment(job.next_shift).format(TimeFormatService.format('shortDateTime')) : '',
            entered_date: job.job_submit_date
              ? moment(job.job_submit_date).format(TimeFormatService.format('shortDate'))
              : '',
            total_workers: job.total_worker_count,
            current_workers: job.current_worker_count,
          }));

          const searchTerm = savedAttribFilters?.find((x) => x.name === 'smart_search');
          if (searchTerm?.value) {
            $scope.filterReports(searchTerm.value);
          }
        })
        .catch((error) => {
          $scope.showSpinner = false;
          AlertService.serverRequestErrorAlert();
        });
    };

    $scope.sort = function (field_id) {
      const descending = $scope.sortParams.field_id === field_id && $scope.sortParams.descending === false;

      $scope.sortParams = {
        field_id,
        descending,
      };
    };

    ///////////////////////////////////////////////////////////////////////////////
    //Schedule actions popover
    let timelineContainerWidth;
    $scope.jobOrderPopover = 'app/views/templates/job_order_popover.html';
    $scope.calculatePlacement = function (id) {
      if (!timelineContainerWidth) {
        timelineContainerWidth = document.getElementsByClassName('jobs-tab-table')[0].offsetWidth;
      }
      const elem = $(`#${id}`);

      if (elem && elem.offset()) {
        if (parseInt(elem.offset().left) > timelineContainerWidth * 0.5) {
          return 'left';
        }
      }
      return 'right';
    };

    $scope.cancelledFilter = function (item) {
      return $scope.customFilters.cancelled || item.status.type !== 7;
    };

    $scope.viewSchedule = function (job) {
      branchFilterService.setSelectedBranch(job.branch_id);
      var enterprise = {
        branch_id: job.branch_id,
        enterprise_id: job.enterprise_id,
        name: job.enterprise_name,
        market_place_id: CURRENT_USER.market_place_info.id,
      };
      EnterpriseFilterService.setSelectedEnterprise(enterprise);
      const storedState = sessionStorage.getItem('calendarState');
      let parsed = {};
      if (storedState) {
        parsed = JSON.parse(storedState);
      }

      parsed = {
        ...parsed,
        startTime: moment
          .utc(job.job_start_date, TimeFormatService.format('shortDate'))
          .startOf('week')
          .add(1, 'd')
          .format(TimeFormatService.format('api')),
        endTime: moment
          .utc(job.job_start_date, TimeFormatService.format('shortDate'))
          .endOf('week')
          .add(1, 'd')
          .format(TimeFormatService.format('api')),
        filters: {
          dateFilter: 'week',
          typeFilter: 'Providers',
        },
      };
      sessionStorage.setItem('calendarState', JSON.stringify(parsed));

      location.href = `/#schedule/${job.job_order_id}`;
    };

    $scope.sessionTracking = function (job) {
      $('div.popover').remove();

      jobManagerService.getJobOrderTracking(job.job_order_id).then(
        (response) => {
          const modalInstance = $modal.open({
            animation: true,
            templateUrl: 'app/views/session/trackSessionModal.html',
            controller: 'TrackSessionModalController',
            size: 'lg',
            backdrop: 'static',
            resolve: {
              passedSession: function () {
                return response;
              },
              passed_job_id: function () {
                return job.job_id;
              },
              passedJobOrderDetails: function () {
                return null;
              },
              passed_current_worker_count: function () {
                return job.current_worker_count;
              },
              passed_total_worker_count: function () {
                return job.total_worker_count;
              },
            },
          });
          modalInstance.result.then(function () {});
        },
        (error) => {
          $scope.showSwal(
            'Error',
            typeof error === 'string' ? error : 'Error contacting the server',
            'error',
            2000,
            false
          );
        }
      );
    };

    $scope.jobViewAction = function (action) {
      if (action === 'viewSchedule') {
        return CURRENT_USER.permissionCheck(38);
      }
    };

    $scope.toggleCancelled = function () {
      $scope.customFilters.cancelled = !$scope.customFilters.cancelled;
    };

    const unwatchPopoverPlacement = $scope.$watch(
      function () {
        return $('div.popover').length;
      },
      function (length) {
        if (length === 1) {
          setTimeout(function () {
            const popover = $('div.popover');
            const calendarAction = popover.find('.calendar-action');
            const calendarHeight = calendarAction.outerHeight();

            if (calendarHeight + parseInt(popover.offset().top) > $(window).innerHeight()) {
              calendarAction.css('margin-top', -calendarHeight + 50 + 'px');
            }

            popover.css('visibility', 'visible');
          });
        }
      }
    );

    $scope.keyAttrib = $localStorage.keyAttribute;

    function getKeyAttribDisplayName() {
      sessionManagerService.getRealmAttribs(enums.Realms.Service).then(
        function (response) {
          // get skill name from realm attribs
          const keyAttribName = response.attrib_list.filter(function (obj) {
            return obj.name === $localStorage.keyAttribute.data_content;
          });

          if (keyAttribName && keyAttribName[0]) {
            $localStorage.keyAttribute.display_name = keyAttribName[0].display_name;
          } else {
            $localStorage.keyAttribute.display_name = 'N/A';
          }

          $scope.keyAttrib = $localStorage.keyAttribute;
        },
        function (reason) {
          AlertService.serverRequestErrorAlert(reason);
        }
      );
    }

    if ($scope.keyAttrib && !$scope.keyAttrib.display_name) {
      getKeyAttribDisplayName();
    }

    $scope.showSwal = async function (title, text, icon, timer, showConfirmButton) {
      const config = {
        title,
        text,
        icon,
        timer,
        showConfirmButton,
      };
      await Swal.fire(config);
    };

    const daysOfWeek = ['Su', 'M', 'Tu', 'W', 'Tr', 'F', 'Sa'];
    $scope.getDaysOfWeek = (job) => {
      job.job_days?.sort((a, b) => a - b);
      return job.job_days?.map((day) => daysOfWeek[day]).join(', ') || '';
    };

    $scope.switchSortUpdated = function (field_id) {
      $scope.sort(field_id);
    };

    $scope.applyFilters = (attribFilters) => {
      savedAttribFilters = attribFilters;
      if (delayFetch) {
        clearTimeout(delayFetch);
        delayFetch = null;
      }
      $scope.getReports();
    };

    $scope.$on('$destroy', function () {
      unwatchSortParams();
      unbindUpdateHandler();
      unbindBranchHandler();
      unwatchPopoverPlacement();
      unwatchEntFilter();
      unwatchBranchFilter();
    });
  },
]);
