import module from '../module';
import Swal from 'sweetalert2';
import moment from 'moment';

export default module.controller('repeatJobOrderModalController', [
  '$scope',
  '$modalInstance',
  '$modal',
  'timeReportNetworkService',
  'selectedJobOrder',
  'verifyAttribute',
  'TimeFormatService',
  'userTriggered',
  function (
    $scope,
    $modalInstance,
    $modal,
    timeReportNetworkService,
    selectedJobOrder,
    verifyAttribute,
    TimeFormatService,
    userTriggered
  ) {
    const errorMessage = 'Please fill out the form before sending the order';
    $scope.showError = false;
    $scope.comments = '';
    $scope.optionalWorkerNumbers = [];
    $scope.selectedWorkerNumber = null;
    $scope.showTimepicker = false;
    $scope.showDateTimepicker = false;
    $scope.userTriggered = userTriggered;
    $scope.timepicker = {
      modelObj: moment(new Date()),
    };
    $scope.timepickerInputModel = '';
    $scope.dateTimepickerModel = '';

    $scope.datetimePickerConfig = {
      startViewTime: 'minute',
      minuteStep: 1,
    };

    $scope.timePickerConfig = {
      hoursStep: 1,
      minuteStep: 1,
      mouseWheel: true,
    };

    const maxWorkerNumer = 100;
    for (let i = 1; i <= maxWorkerNumer; i++) {
      $scope.optionalWorkerNumbers.push(i);
    }

    // modal ok
    $scope.ok = async function (repeat) {
      $scope.closeAllPickers();

      if (!repeat && !$scope.userTriggered) {
        const config = {
          title: 'Warning',
          text: 'Are you sure you do not want to repeat the job order?',
          icon: 'warning',
          confirmButtonColor: '#cc514c',
          confirmButtonText: 'Yes, do not repeat the order',
          cancelButtonText: 'No, take me back',
          showCancelButton: true,
        };

        const result = await Swal.fire(config);
        if (!result.isConfirmed) {
          return;
        }
      }

      $scope.errorMessage = errorMessage;

      if (repeat && (!$scope.dateTimepickerModel || !$scope.timepickerInputModel || !$scope.selectedWorkerNumber)) {
        $scope.showError = true;
        return;
      }
      if (repeat && !$scope.validateDateTimeIsAfter()) {
        $scope.errorMessage = 'Please enter future dates';
        $scope.repeatform.$invalid = true;
        $scope.showError = true;
        return;
      }
      $scope.repeatform.$invalid = false;
      $scope.showError = false;

      if (!selectedJobOrder) {
        return;
      }

      const payload = {
        custom_data: {
          repeat_data: {
            repeat_order: repeat,
          },
        },
      };

      const data = payload.custom_data.repeat_data;

      if (verifyAttribute) {
        payload.verify_attribute = verifyAttribute.data_content;
        payload.attrib_value = selectedJobOrder.attrib_value;
      } else {
        payload.job_id = selectedJobOrder.job_id;
        payload.job_instance = selectedJobOrder.job_instance;
      }

      if (repeat) {
        const utc_date = moment($scope.dateTimepickerModel + ' ' + $scope.timepickerInputModel).utc();

        data.date = utc_date.format(TimeFormatService.format('fullDate'));
        data.time = utc_date.format('HH:mm');
        data.worker_cnt = $scope.selectedWorkerNumber;
        data.comment = $scope.comments;
      }

      timeReportNetworkService
        .postOrderTimesheetRepeatOrders(payload)
        .then((response) => {
          if (response.success) {
            $scope.showSwal('Success', response.message, 'success');
          } else {
            $scope.showSwal('Error', response.message, 'error');
          }
        })
        .catch((error) => {
          $scope.showSwal('Error', error.message, 'error');
        });
    };

    $scope.validateDateTimeIsAfter = function () {
      const date = $scope.dateTimepickerModel;
      const time = moment($scope.timepicker.modelObj).format('HH:mm');
      return moment(date + ' ' + time).isAfter(moment());
    };

    $scope.showSwal = async function (title, text, icon) {
      const config = {
        title,
        text,
        icon,
        confirmButtonColor: '#cc514c',
        confirmButtonText: 'Ok',
      };

      const returnHandler = function () {
        $modalInstance.close();
      };
      await Swal.fire(config);
      returnHandler();
    };

    // modal close, no
    $scope.cancel = function () {
      $modalInstance.close('cancel');
    };

    // DatePicker

    $scope.toggleDateTimePicker = function () {
      $scope.showTimepicker = false;
      $scope.showDateTimepicker = !$scope.showDateTimepicker;
    };

    $scope.changeDate = function (newDate, oldDate) {
      $scope.showDateTimepicker = false;
      $scope.dateTimepickerModel = moment(newDate).format(TimeFormatService.format('fullDate'));
    };

    // End DatePicker

    // TimePicker

    $scope.toggleTimePicker = function () {
      $scope.showDateTimepicker = false;
      $scope.showTimepicker = !$scope.showTimepicker;
    };

    $scope.closeTimePicker = function (isOk) {
      $scope.showTimepicker = false;
      if (isOk && $scope.timepicker) {
        $scope.timepickerInputModel = moment($scope.timepicker.modelObj).format('LT');
      }
    };

    $scope.closeAllPickers = function () {
      $scope.showDateTimepicker = false;
      $scope.showTimepicker = false;
    };

    // End TimePicker

    $scope.openTerms = function (isTerms) {
      $modal.open({
        animation: true,
        templateUrl: 'app/views/reporting/timeReports/termsAndConditionsModal.html',
        controller: 'termsAndConditionsModalController',
        size: 'lg',
        backdrop: 'static',
        resolve: {
          isTerms: isTerms,
        },
      });
    };
  },
]);
