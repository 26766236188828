import angular from 'angular';
import module from '../module';
export default module.controller('NewEnterpriseModalController', [
  '$scope',
  '$modalInstance',
  'AlertService',
  'enterpriseListService',
  'AuthenticationService',
  'timezoneService',
  'marketplaceService',
  'DISPLAY_WEB_URL',
  'generalDataService',
  'WizardHandler',
  'EnterpriseFilterService',
  'branch_selection',
  'branchFilterService',
  'PERMISSIONS',
  'enterpriseUtilityService',
  function (
    $scope,
    $modalInstance,
    AlertService,
    enterpriseListService,
    AuthenticationService,
    timezoneService,
    marketplaceService,
    DISPLAY_WEB_URL,
    generalDataService,
    WizardHandler,
    EnterpriseFilterService,
    branch_selection,
    branchFilterService,
    PERMISSIONS,
    enterpriseUtilityService
  ) {
    $scope.initialize = function () {
      $scope.CURRENT_USER = AuthenticationService.getCurrentUser();
      $scope.keySession = generalDataService.getKeySession();
      $scope.submitBtnDisbaled = false;
      $scope.showSpinner = true;
      $scope.wizardStepText = 'Hide Steps';
      $scope.webUrl = DISPLAY_WEB_URL;
      $scope.type = 'Add New Enterprise'; // Modal Title
      $scope.btnLabel = 'Add Enterprise';
      $scope.loaded = true;
      $scope.formData = {
        marketplace: $scope.CURRENT_USER.market_place_info.id,
        details: {
          zero_staff_pricing: true,
          checkin_enabled: -1,
        },
        branch: branch_selection && branch_selection.id,
        geoFenceOption: -1
      };
      $scope.showBranch =
        $scope.CURRENT_USER.user_type === 2 && $scope.CURRENT_USER.permissionCheck(PERMISSIONS.BRANCH_TAB);

      $scope.showCheckin =
        $scope.CURRENT_USER.user_type === 2 && $scope.CURRENT_USER.permissionCheck(PERMISSIONS.WORKN_CHECKIN);

      $scope.headerLogoIndex = 1;
      $scope.tabIconIndex = 1;
      $scope.headerLogo = {
        type: 'image',
        editType: 'edit',
        name: 'headerLogo',
        display_name: 'Header Logo',
        type_flags: {
          required: false,
        },
        value: '',
        attrib_list: [
          {
            attrib_id: 1,
            type: 'image',
            data: '',
          },
        ],
        attrib_id: 1,
      };

      $scope.tabIcon = {
        type: 'image',
        editType: 'edit',
        name: 'tabIcon',
        display_name: 'Web Tab Icon',
        type_flags: {
          required: false,
        },
        value: '',
        attrib_list: [
          {
            attrib_id: 1,
            type: 'image',
            data: '',
          },
        ],
        attrib_id: 1,
      };

      $scope.loginBackground = {
        type: 'image',
        editType: 'edit',
        name: 'loginBackground',
        display_name: 'Login Background',
        type_flags: {
          required: false,
        },
        value: '',
        attrib_list: [
          {
            attrib_id: 1,
            type: 'image',
            data: '',
          },
        ],
        attrib_id: 1,
      };

      $scope.loginImage = {
        type: 'image',
        editType: 'edit',
        name: 'loginImage',
        display_name: 'Login Image',
        type_flags: {
          required: false,
        },
        value: '',
        attrib_list: [
          {
            attrib_id: 1,
            type: 'image',
            data: '',
          },
        ],
        attrib_id: 1,
      };

      $scope.checkinOptions = [
        { id: -1, name: 'Marketplace Setting' },
        { id: 0, name: 'Off' },
        { id: 1, name: 'On' },
      ];

      $scope.geoFenceSettings = [
        { id: -1, name: 'Follow Marketplace Setting' },
        { id: 0, name: 'Override Marketplace Setting' },
      ];


      $scope.gpsWarningOptions = ['WARNING', 'STOP', 'OFF'];

      timezoneService.getTimezones().then(function (timezones) {
        $scope.timezones = timezones;
      });

      marketplaceService.getMarketplace().then(function (marketplace) {
        angular.extend($scope.formData.details, {
          on_demand_wait_time: marketplace.details.on_demand_wait_time,
          scheduled_wait_time: marketplace.details.scheduled_wait_time,
          minute_per_mile: marketplace.details.minute_per_mile,
        });
      });

      branchFilterService.fetchBranchesForList().then((branches) => {
        $scope.branches = branches;
      });
    };

    $scope.toggleWizard = function () {
      if ($scope.hideWizard) {
        $scope.wizardStepText = 'Show Steps';
      } else {
        $scope.wizardStepText = 'Hide Steps';
        WizardHandler.wizard().goTo(1);
        WizardHandler.wizard().goTo(0);
      }
    };

    $scope.showPrevStep = function () {
      return WizardHandler.wizard().currentStepNumber() > 1;
    };

    $scope.showNextStep = function () {
      return WizardHandler.wizard().currentStepNumber() < WizardHandler.wizard().totalStepCount();
    };

    $scope.ok = function () {
      $scope.submitBtnDisbaled = true;

      if ($scope.validateFormData()) {
        $scope.sendToServer();
      }
    };

    $scope.validateFormData = function () {
      const formErrors = enterpriseUtilityService.validateEnterpriseFormData($scope.formData);
      if (formErrors.length > 0) {
        AlertService.errorListAlert(formErrors, 3000);
        $scope.submitBtnDisbaled = false;
        return false;
      }
      return true;
    };

    $scope.cancel = function () {
      $modalInstance.dismiss('cancel');
      $scope.formData = {};
    };

    $scope.sendToServer = function () {
      if ($scope.headerLogo.jpeg) {
        $scope.formData.details.header_image = $scope.headerLogo.jpeg;
      }
      if ($scope.tabIcon.jpeg) {
        $scope.formData.details.favicon = $scope.tabIcon.jpeg;
      }
      if ($scope.loginBackground.jpeg) {
        $scope.formData.details.login_page_background = $scope.loginBackground.jpeg;
      }
      if ($scope.loginImage.jpeg) {
        $scope.formData.details.login_page_image = $scope.loginImage.jpeg;
      }
      if (!$scope.formData.branch || $scope.formData.branch === -1) {
        $scope.formData.branch = null;
      }
      if ($scope.formData.geoFenceOption === -1) {
        $scope.formData.details.checkin_buffer = -1;
        $scope.formData.details.checkin_radius = -1;
        $scope.formData.details.checkin_radius_buffer = -1;
        $scope.formData.details.warning_radius = -1;
        $scope.formData.details.checkout_radius = -1;
        $scope.formData.details.checkout_radius_buffer = -1;
        $scope.formData.details.checkin_gps_warning = 'MarketPlace';
        $scope.formData.details.gps_warning_radius = -1;
      }
      $scope.handleMarketplaceDefaults();

      enterpriseListService.addEnterprise($scope.formData).then(
        function (res) {
          EnterpriseFilterService.addEnterprise(res);
          AlertService.successAlert('New Enterprise was added.');
          $modalInstance.close();
        },
        function (reason) {
          AlertService.detailedErrorAlert(reason);
          $scope.submitBtnDisbaled = false;
        }
      );
    };

    $scope.handleMarketplaceDefaults = function () {
      // Ensure that we send up zeroes when user hasn't touched marketplace defaults
      if ($scope.enterpriseForm.nowJobWaitTime.$pristine) {
        $scope.formData.details.on_demand_wait_time = 0;
      }
      if ($scope.enterpriseForm.scheduleJobWaitTime.$pristine) {
        $scope.formData.details.scheduled_wait_time = 0;
      }
      if ($scope.enterpriseForm.minsPerMile.$pristine) {
        $scope.formData.details.minute_per_mile = 0;
      }
    };

    $scope.geoFenceSettingsOptionChanged = function () {
      if ($scope.formData.geoFenceOption === 0) {
        // set Defaults
        $scope.formData.details.checkin_buffer = 30;
        $scope.formData.details.checkin_radius = 500;
        $scope.formData.details.checkin_radius_buffer = 180;
        $scope.formData.details.warning_radius = 1;
        $scope.formData.details.checkout_radius = 500;
        $scope.formData.details.checkout_radius_buffer = 15;
        $scope.formData.details.checkin_gps_warning = 'STOP';
        $scope.formData.details.gps_warning_radius = 300;
      }
    };

    $scope.initialize();
  },
]);
