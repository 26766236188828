import module from './module';
import * as enums from '../enums';

export default module.factory('AuthenticationService', [
  '$http',
  '$rootScope',
  '$localStorage',
  'BASE_URL',
  '$q',
  'CurrentUser',
  'PERMISSIONS',
  'NgrxStore',
  function ($http, $rootScope, $localStorage, BASE_URL, $q, CurrentUser, PERMISSIONS, NgrxStore) {
    const currentUser = {};
    let initialData = null;

    // Create and Store current User
    function _retrieveCurrentUser(userData) {
      if (userData) {
        let instance = currentUser[userData.status];
        if (instance) {
          return instance;
        } else {
          instance = new CurrentUser(userData);
          currentUser[userData.status] = instance;
          return instance;
        }
      }
    }

    // Create localStorage websocket array for storing messages after page reloads
    function setwebSocketStorageObj(user, enterpriseList) {
      const enterprises = user.enterprise_info ? [user.enterprise_info.id] : enterpriseList.map((x) => x.enterprise_id);
      NgrxStore.dispatch({
        type: '[Common] Init Websocket Messages',
        enterprises,
      });
    }

    function loadInitialData(token, url, deferred) {
      $http
        .post(BASE_URL + '/get_initial_data', { app_token: token, web_url: url })
        .then(function (res) {
          const response = res.data;
          initialData = response;
          $rootScope.$broadcast('loadedInitialData', initialData);
          deferred.resolve(response);
        })
        .catch(function () {
          deferred.reject();
        });
    }

    function _login(username, password, deferred) {
      $http.post(BASE_URL + '/login', { username, password, web_login: true }).then(function (res) {
        const response = res.data;
        handleLoginResponse(response, deferred);
      });
    }

    function _ssoLogin(payload, deferred) {
      if (payload) {
        $http.post(BASE_URL + '/login', { is_sso: true, sso_user_id: payload.sso_user_id, sso_expires_at: payload.sso_expires_at, sso_token: payload.sso_token, web_login: true }).then(function (res) {
             const response = res.data;
          
          handleLoginResponse(response, deferred);
        });
      }
    }

    function handleLoginResponse(response, deferred) {
      if (response.success) {
        // These lists can be very huge and will blow up local storage. Thus load these lists on demand
        delete response.enterprise_list;
        delete response.branch_list;

        $localStorage.current_user = response;
        $localStorage.token = response.token;
        // setwebSocketStorageObj(response); // move this to main controller after loading enterprise list
        deferred.resolve(response);
      } else {
        deferred.resolve(response);
      }
    }

    return {
      getInitData: function (token, url) {
        const deferred = $q.defer();
        loadInitialData(token, url, deferred);
        return deferred.promise;
      },
      getInitDataCache: function () {
        if (initialData) {
          return $q(function (resolve, reject) {
            resolve(initialData);
          });
        }

        const deferred = $q.defer();
        $rootScope.$on('loadedInitialData', function (event, response) {
          deferred.resolve(response);
        });
        return deferred.promise;
      },
      passwordReset: function (submissionDetails, callback) {
        $http
          .post(BASE_URL + '/remember_my_password/', submissionDetails)
          .then(function (res) {
            const response = res.data;
            callback(response);
          })
          .catch(function (response) {
            callback(response);
          });
      },
      Login: function (username, password) {
        const deferred = $q.defer();
        _login(username, password, deferred);
        return deferred.promise;
      },
      ssoLogin: function (payload) {
        const deferred = $q.defer();
        _ssoLogin(payload, deferred);
        return deferred.promise;
      },
      SetWebSocketStorageObj: function (user, enterpriseList) {
        const deferred = $q.defer();
        setwebSocketStorageObj(user, enterpriseList, deferred);
        return deferred.promise;
      },
      SetWebSocketStorageObj: function (user, enterpriseList) {
        const deferred = $q.defer();
        setwebSocketStorageObj(user, enterpriseList, deferred);
        return deferred.promise;
      },
      SetCredentials: function (token, callback) {
        $localStorage.token = token;
        $http.defaults.headers.common['Authorization'] = 'Token ' + token; // jshint ignore:line
        callback(token);
      },
      ClearCredentials: function (callback) {
        $http.post(BASE_URL + '/logout', {}).then(
          function success(response) {
            delete $localStorage.token;
            delete $localStorage.generalData;
            delete $localStorage.current_user;
            $http.defaults.headers.common['Authorization'] = 'Token ';
            callback(response.data);
          },
          function error(response) {
            callback(response);
          }
        );
      },
      isLoggedIn: function () {
        return $localStorage.token;
      },
      getCurrentUser: function () {
        const user = _retrieveCurrentUser($localStorage.current_user);
        // remove a privilege for test don't push this to PRODUCTION
        // user.permission_info = user.permission_info.filter(perm => (perm.priv_id != 76 && perm.priv_id != 77))
        return user;
      },
      getCurrencySymbol: function () {
        const user = _retrieveCurrentUser($localStorage.current_user);
        return (user.config_info && user.config_info.currency_type) || '$';
      },
      getCurrentUserType: function () {
        const user = _retrieveCurrentUser($localStorage.current_user);
        if (!user) {
          return null;
        } else {
          switch (user.user_type) {
            case enums.UserType.MarketPlaceAdmin:
              if (user.permissionCheck(PERMISSIONS.MULTI_BRANCH_ADMIN)) {
                return enums.UserType.MultiBranchAdmin;
              } else {
                return enums.UserType.MarketPlaceAdmin;
              }
            case enums.UserType.EnterpriseAdmin:
              return enums.UserType.EnterpriseAdmin;
            case enums.UserType.BranchAdmin: {
              return enums.UserType.BranchAdmin;
            }
          }
        }
      },
      isBreakPenaltyEnabled() {
        const list = initialData?.market_place_list;
        if (list && list.length) {
          return list[0].break_penalty_enabled;
        }
        return false;
      },
      isMPSupervisorSignOffEnabled() {
        const list = initialData?.market_place_list;
        if (list && list.length) {
          return list[0].supervisor_signoff_marketplaces ?? false;
        }
        return false;
      }
    };
  },
]);
