import module from './module';
import * as enums from '../enums';
import Swal from 'sweetalert2';
import moment from 'moment';
import * as rxjs from 'rxjs';
import * as operators from 'rxjs/operators';

export default module.controller('MainController', [
  '$scope',
  '$modal',
  '$location',
  '$localStorage',
  'AuthenticationService',
  '$timeout',
  'WebsocketService',
  'AlertService',
  'DOMAIN',
  'sessionManagerService',
  'WEB_URL',
  'APP_TOKEN',
  'generalDataService',
  'PERMISSIONS',
  'branchFilterService',
  'VERSION',
  'AppStateService',
  '$rootScope',
  'EnterpriseFilterService',
  'NgrxStore',
  async function (
    $scope,
    $modal,
    $location,
    $localStorage,
    AuthenticationService,
    $timeout,
    WebsocketService,
    AlertService,
    DOMAIN,
    sessionManagerService,
    WEB_URL,
    APP_TOKEN,
    generalDataService,
    PERMISSIONS,
    branchFilterService,
    VERSION,
    AppStateService,
    $rootScope,
    enterpriseFilterService,
    NgrxStore
  ) {
    //dev testing local host use
    $scope.version = VERSION.semantic;
    var url, icon;
    if (
      window.location.host === '192.168.0.102:8000' ||
      window.location.host === 'localhost:8000' ||
      window.location.host === '127.0.0.1:5500' ||
      window.location.host === 'workntest.z13.web.core.windows.net' ||
      window.location.host === 'worknadhoc.z13.web.core.windows.net'
    ) {
      url = WEB_URL;
    } else {
      url = window.location.host;
    }

    $scope.icons = {
      person: `${enums.AssetsFolder.svg}profile-person.svg`,
      marketplace: `${enums.AssetsFolder.svg}profile-marketplace.svg`,
    };

   
    $scope.params = $location.search();
    if ($scope.params.showModal === 'true') {
      if (!document.querySelector('.modal')) {
        $modal.open({
          animation: $scope.animationsEnabled,
          templateUrl: 'app/views/login/loggedOut.html',
          size: 'lg',
          backdrop: 'static',
          controller: 'LoggedOutController',
          resolve: {
            passedData() {
              return {
                email: $scope.params.email,
                token: $scope.params.token,
              };
            },
          },
        });
      }
    }

    if ($scope.params.email && $scope.params.token) {
      if (!document.querySelector('.modal')) {
        $modal.open({
          animation: $scope.animationsEnabled,
          templateUrl: 'app/views/login/setupModal.html',
          size: 'lg',
          backdrop: 'static',
          controller: 'SetupController',
          resolve: {
            passedData() {
              return {
                email: $scope.params.email,
                token: $scope.params.token,
              };
            },
          },
        });
      }
    }

    if (!$scope.current_user && !$scope.bannerImg) {
      AuthenticationService.getInitData(APP_TOKEN, url).then(function (response) {
        if (response.success) {
          AppStateService.initData = { ...response, domain: DOMAIN };
          $scope.bannerImg = DOMAIN + response.enterprise_list[0].header_image;
          $localStorage.bannerImg = $scope.bannerImg;
          $scope.footerCopyright = response.enterprise_list[0].footer_copyright;
          $scope.footerEmail = response.enterprise_list[0].footer_email;
          $scope.footerPhone = response.enterprise_list[0].footer_phone || {};
          $localStorage.generalData = response.general_data;
          $localStorage.keyAttribute = response.general_data.filter(function (obj) {
            return obj.data_name == 'key_attribute';
          })[0];

          try {
            const webTimeout = response.general_data.find((x) => x.data_name === 'web_timeout')?.data_content;
            if (!webTimeout) {
              delete $localStorage.webTimeout;
            } else {
              const timeToLogout = +$localStorage.timeToLogout;
              if (!isNaN(timeToLogout) && timeToLogout < Date.now()) {
                delete $localStorage.timeToLogout;
                $scope.logout();
                return;
              } else {
                $localStorage.webTimeout = +webTimeout * 1000 * 60;
              }
            }

            $scope.setupTimeout();
          } catch (e) {
            console.log('Could not set up timeout');
          }

          if (response.enterprise_list[0].login_page_image) {
            $scope.loginImage = DOMAIN + response.enterprise_list[0].login_page_image;
          }
          if (response.enterprise_list[0].login_page_background) {
            $scope.loginBackground = 'url(' + DOMAIN + response.enterprise_list[0].login_page_background + ')';
          }

          icon = response.enterprise_list[0].favicon;
          if (icon) {
            icon = DOMAIN + icon;
          }

          $scope.faviconData = {
            icon: icon || `${enums.AssetsFolder.images}favicon.ico`,
            label: response.enterprise_list[0].favicon_label || 'Stratus InPerson',
          };

          document.title = $scope.faviconData.label;

          if (response.enterprise_list[0].main_color) {
            $scope.$broadcast('UI.ColorChange', {
              main: response.enterprise_list[0].main_color,
              highlight: response.enterprise_list[0].highlight_color,
            });
          }
          $scope.key_session = generalDataService.getKeySession().toUpperCase();
        }
      });
    }
    $localStorage.ssoLogin = false;
    $scope.ssoLogin = false;
    ssoLogin();
    
    $scope.token = $localStorage.token;
    $scope.current_user = AuthenticationService.getCurrentUser();
    $scope.current_user_type = AuthenticationService.getCurrentUserType();
    $scope.enterpriseList = [];
    if ($scope.current_user) {
      const firstName = $scope.current_user.profile_info?.find((x) => x.name === 'first_name')?.data;
      const lastName = $scope.current_user.profile_info?.find((x) => x.name === 'last_name')?.data;
      const userName = $scope.current_user.profile_info?.find((x) => x.name === 'username')?.data;

      if (firstName && lastName) {
        $scope.displayName = `${firstName} ${lastName}`;
      } else {
        $scope.displayName = userName;
      }

      $scope.displayEmail = $scope.current_user.profile_info?.find((x) => x.name === 'email_address')?.data;
      $scope.displayMarketplace = $scope.current_user?.market_place_info?.name;
      $scope.userPopover = 'app/views/templates/user_popover.html';
    }

    if ($scope.current_user_type === enums.UserType.MarketPlaceAdmin) {
      $scope.isMarketplace = true;
    } else if ($scope.current_user_type === enums.UserType.MultiBranchAdmin) {
      $scope.isMarketplace = true;
      await loadBranchesAsync();
    } else if ($scope.current_user_type === enums.UserType.BranchAdmin) {
      await loadBranchesAsync();
    }
    loadEnterpriseList();

    if ($scope.current_user && $scope.current_user.enterprise_info) {
      $scope.titleLabel = $scope.current_user.enterprise_info.name;
    } else if ($scope.current_user && $scope.current_user.market_place_info) {
      $scope.titleLabel = $scope.current_user.market_place_info.name;
      // Get default DOW from marketplace info
    }

    if ($scope.current_user) {
      setStartOfWorkWeek($scope.current_user);
    }
    // logic to load correct session colors css based on load_all_jobs flag
    if ($scope.current_user) {
      $scope.load_all_jobs = $scope.current_user.market_place_info ? $scope.current_user.market_place_info.load_all_jobs : false;
      const sessionStateColorScss = $scope.load_all_jobs ? 'session-colors-new.css' : 'session-colors-old.css';
      const head = document.getElementsByTagName('head')[0];
      const themeLink = document.getElementById(
        'session-states-colors'
      );
      if (themeLink) {
        themeLink.href = sessionStateColorScss;
      } else {
        const style = document.createElement('link');
        style.id = 'session-state-theme';
        style.rel = 'stylesheet';
        style.href = `${sessionStateColorScss}`;

        head.appendChild(style);
      }
    }

    function setStartOfWorkWeek(user) {
      let workWeekStart = 0;
      if (user) {
        if (user.market_place_info && user.market_place_info.workweek_start) {
          workWeekStart = user.market_place_info.workweek_start;
        } else if (user.enterprise_info && user.enterprise_info.workweek_start) {
          workWeekStart = user.enterprise_info.workweek_start;
        }
      }

      moment.updateLocale('en', {
        week: {
          dow: workWeekStart,
        },
      });
    }

    async function loadBranchesAsync() {
      const branchesList = await branchFilterService.fetchBranches();
      NgrxStore.dispatch({
        type: '[Common] Branch list fetched',
        result: branchesList,
      });
      $scope.$emit('Branches.Updated');
      setTimeout(() => {
        branchFilterService.setSelectedBranch(branchesList[0]);
      });
    }

    async function loadEnterpriseList() {
      if ($scope.current_user) {
        $scope.enterprisesLoading = true;
        const result = await rxjs.firstValueFrom(
          NgrxStore.select((x) => x.commonData.enterpriseList).pipe(
            operators.filter((x) => !!x),
            operators.finalize(() => ($scope.enterprisesLoading = false))
          )
        );
        $scope.enterpriseList = result || [];

        AuthenticationService.SetWebSocketStorageObj($scope.current_user, $scope.enterpriseList);
      }
    }

    function getKeyAttribDisplayName() {
      if ($scope.current_user && $scope.token && !$scope.keyAttrib) {
        sessionManagerService.getRealmAttribs(enums.Realms.Service).then(
          function (response) {
            var keyAttrib = response.attrib_list.filter(function (obj) {
              return obj.name == $localStorage.keyAttribute.data_content;
            })[0];

            if (keyAttrib && keyAttrib.display_name) {
              $localStorage.keyAttribute.display_name = keyAttrib.display_name;
            } else {
              $localStorage.keyAttribute.display_name = 'N/A';
            }

            $scope.keyAttrib = $localStorage.keyAttribute;
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
          }
        );
      }
    }

    getKeyAttribDisplayName();
    ////////////////////////////////////////////////////////////////////////////
    var setSupportNumber = function () {
      if ($scope.current_user.user_type == enums.UserType.MarketPlaceAdmin) {
        $scope.support_number = $scope.current_user.market_place_info.support_phone;
      } else if (
        $scope.current_user.user_type == enums.UserType.EnterpriseAdmin ||
          $scope.current_user.user_type == enums.UserType.Requester
      ) {
        $scope.support_number = $scope.current_user.enterprise_info.support_phone;
      }
    };
    if ($scope.current_user) {
      setSupportNumber();
    }
    ////////////////////////////////////////////////////////////////////////////
    //Permission Check
    function sessionTabPermissions() {
      if ($scope.current_user && $scope.current_user.permission_info) {
        $scope.sessionTabPermission = $scope.current_user.permissionCheck(PERMISSIONS.SESSION_TAB);
        $scope.reportTabPermission = $scope.current_user.permissionCheck(PERMISSIONS.REPORT_TAB);
        $scope.adminTabPermission = $scope.current_user.permissionCheck(PERMISSIONS.ADMIN_TAB);
        $scope.workersTabPermission = $scope.current_user.permissionCheck(PERMISSIONS.PROVIDER_TAB);
        $scope.supportTabPermission = $scope.current_user.permissionCheck(PERMISSIONS.SUPPORT_TAB);
        $scope.scheduleTabPermission = $scope.current_user.permissionCheck(PERMISSIONS.CALENDAR_SCHEDULE_TAB);
        $scope.jobsTabPermission = $scope.current_user.permissionCheck(PERMISSIONS.JOBS);
        $scope.dashboardTabPermission = $scope.current_user.permissionCheck(PERMISSIONS.NEW_ANALYTICS_TAB);
        if ($scope.current_user && $scope.current_user.user_type === enums.UserType.MasterUser) {
          $scope.sessionTabPermission = false;
          $scope.reportTabPermission = false;
          $scope.adminTabPermission = false;
          $scope.supportTabPermission = false;
          $scope.scheduleTabPermission = false;
          $scope.jobsTabPermission = false;
          $scope.analyticsTabPermission = $scope.current_user.permissionCheck(PERMISSIONS.NEW_ANALYTICS_TAB);
        } else if ($scope.current_user && $scope.current_user.permission_info) {
          $scope.sessionTabPermission = $scope.current_user.permissionCheck(PERMISSIONS.SESSION_TAB);
          $scope.reportTabPermission = $scope.current_user.permissionCheck(PERMISSIONS.REPORT_TAB);
          $scope.adminTabPermission = $scope.current_user.permissionCheck(PERMISSIONS.ADMIN_TAB);
          $scope.supportTabPermission = $scope.current_user.permissionCheck(PERMISSIONS.SUPPORT_TAB);
          $scope.scheduleTabPermission = $scope.current_user.permissionCheck(PERMISSIONS.CALENDAR_SCHEDULE_TAB);
          $scope.jobsTabPermission = $scope.current_user.permissionCheck(PERMISSIONS.JOBS);
          $scope.analyticsTabPermission = $scope.current_user.permissionCheck(PERMISSIONS.NEW_ANALYTICS_TAB);
        }
      }
    }
    sessionTabPermissions();
    ////////////////////////////////////////////////////////////////////////////////
    // Logout
    $scope.logout = function (userAction) {
      AuthenticationService.ClearCredentials(function (response) {
        if (response.success) {
          WebsocketService.closeSocket();
          delete $scope.token;
          const newRoute = `/#/login?showModal=${!userAction}`;
          location.href = newRoute;
          $timeout(() => location.reload(), 200);
        } else {
          AlertService.errorAlert(response.message);
          delete $localStorage.token;
          delete $scope.token;
          location.href = '/#/login?showModal=' + userAction ? 'false' : 'true';
        }
      });
    };

    // Login
    $scope.login = function () {
      if ($localStorage.generalData) {
        $localStorage.ssoLogin = false;
        $scope.ssoLogin = false;
        $scope.dataLoading = true;
        AuthenticationService.Login($scope.username, $scope.password).then(
          function (response) {
            loginResponseHandler(response);
          },
          function (reason) {
            AlertService.serverRequestErrorAlert(reason);
          }
        );
      } else {
        AlertService.errorAlert('Could not contact server for setting initial data');
      }
    };
 
    // sso login
    async function ssoLogin() {
      // Check if the URl is coming from bold
      const currentPath = $location.path();
      if ($scope.params && $scope.params.userId && $scope.params.userHash && currentPath === '/ssoLogin') {
        delete $scope.token;
        delete $localStorage.generalData;
        delete $localStorage.current_user;
        $scope.userId = $scope.params.userId;
        $scope.userHash = $scope.params.userHash ? decodeURIComponent($scope.params.userHash) : undefined;
        $scope.ext_order_id = $scope.params.ext_order_id;
        $scope.exp_date = $scope.params.exp_date ? decodeURIComponent($scope.params.exp_date) : undefined;
        if (!$scope.exp_date || !$scope.userHash) {
          const config = {
            title: 'Error',
            text: 'Unable to login the user.',
            icon: 'error',
            showConfirmButton: true,
          };
          await Swal.fire(config);
        } else {
          if ($scope.userId && $scope.userHash && $scope.exp_date) {
            $localStorage.ssoLogin = true;
            $scope.ssoLogin = true;
            $scope.dataLoading = true;

            const ssoLoginPayload = {
              is_sso: true,
              sso_user_id: $scope.userId,
              sso_expires_at: $scope.exp_date,
              sso_token: $scope.userHash,
            };
            const ssoResponse = await AuthenticationService.ssoLogin(ssoLoginPayload);
            $scope.current_user = await AuthenticationService.getCurrentUser();
            loginResponseHandler(ssoResponse);
          } else {
            AlertService.errorAlert('Unable to login.');
          }
        }
      }
    };

    async function loginResponseHandler(response) {
      if (response.success) {
        sessionTabPermissions();
        setCredentials(response.token);
      } else {
        $scope.error = response.message;
        $scope.dataLoading = false;
        if (response.is_password_expired) {
          $localStorage.token = response.token;
          location.href = `/#/reset-password?passwordExpired=${response.is_password_expired}`;
        } else {
          AlertService.errorAlert(response.message, 10000);
        }
      }
    };
    // Forgot Password
    $scope.forgotPassword = function () {
      location.href = '/#/forgot-password';
    };

    function setCredentials(token) {
      AuthenticationService.SetCredentials(token, function (response) {
        if ($localStorage.token) {
          $scope.redirect();
          viewReload();
        } else {
          $scope.login();
        }
      });
    }

    $scope.redirect = function () {
      let currentLocationPath = $location.path();
     
      if ($scope.current_user && $scope.params && $scope.params.screen == 'time-verification') {
        currentLocationPath = `/reporting/time-verification`; //?entId=${$scope.params.entId}&jobId=${$scope.params.jobId}&${$scope.params.jobInstanceId}`;
      }

      switch (currentLocationPath) {
        case '/schedule':
          if ($localStorage.token && $scope.scheduleTabPermission) {
            const queryString = window.location.href.split('?')[1];
            let target = `/#${currentLocationPath}`;
            if (queryString) {
              target += `?${queryString}`;
            }
            location.href = target;
          }
          break;
        case '/job-order-report':
          if ($localStorage.token && ($scope.jobsTabPermission || $scope.sessionTabPermission)) {
            location.href = `/#${currentLocationPath}`;
          }
          break;
        case '/session':
          if ($localStorage.token && $scope.sessionTabPermission) {
            location.href = `/#${currentLocationPath}`;
          }
          break;
        case '/analytics':
          if ($localStorage.token && $scope.dashboardTabPermission) {
            location.href = `/#${currentLocationPath}`;
          }
          break;
        case '/reporting':
        case '/reporting/time-verification':
        case '/reporting/analytics':
        case '/reporting/overview':
        case '/reporting/map-views':
        case '/reporting/time-verification-original':
          if ($localStorage.token && $scope.reportTabPermission) {
            const queryString = window.location.href.split('?')[1];
            let target = `/#${currentLocationPath}`;
            if (queryString) {
              target += `?${queryString}`;
            }
            location.href = target;
          }
          break;
        case '/admin':
          if ($localStorage.token && $scope.adminTabPermission) {
            location.href = `/#${currentLocationPath}`;
          }
          break;
        case '/support':
          if ($localStorage.token && $scope.supportTabPermission) {
            location.href = `/#${currentLocationPath}`;
          }
          break;
        default:
          if ($localStorage.ssoLogin) {
            if ($scope.token && $scope.scheduleTabPermission && $scope.ext_order_id) {
              location.href = `/#/schedule?ext_order_id=${$scope.ext_order_id}`; // update this redirect
            }
          } else {
            if ($localStorage.token && $scope.scheduleTabPermission) {
              location.href = '/#/schedule';
            } else if ($localStorage.token && ($scope.jobsTabPermission || $scope.sessionTabPermission)) {
              location.href = '/#/job-order-report';
            } else if ($localStorage.token && $scope.sessionTabPermission) {
              location.href = '/#/session';
            } else if ($localStorage.token && $scope.dashboardTabPermission) {
              location.href = '/#/analytics';
            } else if ($localStorage.token && $scope.reportTabPermission) {
              location.href = '/#/reporting';
            } else if ($localStorage.token && $scope.adminTabPermission) {
              location.href = '/#/admin';
            } else if ($localStorage.token && $scope.supportTabPermission) {
              location.href = '/#/support';
            } else if ($localStorage.token && $scope.current_user) {
              $scope.error = 'Your credentials do not allow you to access the web. Please use the app';
              $timeout(function () {
                $scope.logout();
              }, 3000);
            }
          }
          break;
      }
    };
    $scope.redirect();

    function viewReload() {
      $timeout(function () {
        window.location.reload(); //force reload so page will recognize token and show menu
      }, 300);
    }

    ////////////////////////////////////////////////////////////////////////////////
    // Login Help Modal
    $scope.animationsEnabled = true;
    $scope.showHelpModal = function () {
      var modalInstance = $modal.open({
        animation: $scope.animationsEnabled,
        templateUrl: 'app/views/login/loginHelpModal.html',
        backdrop: 'static',
        controller: 'ModalController',
      });
      modalInstance.result.then(function (selectedItem) {
        // TODO do stuff here
      });
    };

    $scope.notificationOpen = false;
    $scope.toggleNotification = function () {
      $scope.reviewOpen = false;
      $scope.notificationOpen = !$scope.notificationOpen;
      if ($scope.notificationOpen) {
        $scope.newNotification = false;
        $scope.notificationCount = 0;
      }
    };
    $scope.newNotification = false;
    $scope.notificationCount = 0;

    // Store the message until the user clicks on a button
    $scope.headerMessage;
    $scope.$on('NEW_VERSION_RELEASED', function (event, args) {
      $scope.headerMessage = args.headerMessage;
      setTimeout(async () => {
        const returnHandler = function (isConfirm) {
          if (isConfirm) {
            window.location.reload();
          } else {
            $scope.new_version_alert = $scope.headerMessage;
          }
        };

        const config = {
          title: args.title,
          text: args.text,
          showCancelButton: true,
          confirmButtonColor: '#cc514c',
          confirmButtonText: args.confirmButtonText,
          cancelButtonText: args.cancelButtonText,
          icon: 'warning',
        };

        const { value: returnValue } = await Swal.fire(config);
        returnHandler(returnValue);
      }, 1000);
    });

    $scope.$on('NOTIFICATION_UPDATE', function (event, args) {
      if (!$scope.notificationOpen) {
        $scope.newNotification = true;
        $scope.notificationCount++;
      }
    });

    $scope.reviewOpen = false;
    $scope.toggleReview = function () {
      $scope.notificationOpen = false;
      $scope.reviewOpen = !$scope.reviewOpen;
    };

    $scope.$on('PleaseReview.Fetched', function (event, number) {
      $scope.reviewCount = number;
    });

    $rootScope.$on('Logout.Requested', () => {
      $scope.logout(true);
    });

    $scope.setupTimeout = () => {
      let webTimeout;

      const userActionHandler = () => {
        clearTimeout(webTimeout);
        $localStorage.timeToLogout = Date.now() + $localStorage.webTimeout;
        webTimeout = setTimeout(() => {
          $scope.logout();
          document.removeEventListener('click', raiseUserActionEvent);
          document.removeEventListener('keydown', raiseUserActionEvent);
          document.removeEventListener('userAction', userActionHandler);
        }, $localStorage.webTimeout);
      };

      const raiseUserActionEvent = () => {
        const event = new CustomEvent('userAction');
        document.dispatchEvent(event);
      };

      if ($localStorage.webTimeout && $scope.token) {
        document.addEventListener('click', raiseUserActionEvent);
        document.addEventListener('keydown', raiseUserActionEvent);
        document.addEventListener('userAction', userActionHandler);

        userActionHandler();
      }
    };
  },
]);
