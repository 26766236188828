import angular from 'angular';

export const templates = angular.module('templates', []).run([
  '$templateCache',
  function ($templateCache) {
    const paths = [
      {
        name: 'app/views/admin/apiLogs/apiLogsDetailModal.html',
        contents: require('raw-loader!../views/admin/apiLogs/apiLogsDetailModal.html').default,
      },
      {
        name: 'app/views/admin/apiLogs/apiLogsTab.html',
        contents: require('raw-loader!../views/admin/apiLogs/apiLogsTab.html').default,
      },
      {
        name: 'app/views/admin/branch/branchModal.html',
        contents: require('raw-loader!../views/admin/branch/branchModal.html').default,
      },
      {
        name: 'app/views/admin/branch/branchTab.html',
        contents: require('raw-loader!../views/admin/branch/branchTab.html').default,
      },
      {
        name: 'app/views/admin/enterprise/enterpriseModal.html',
        contents: require('raw-loader!../views/admin/enterprise/enterpriseModal.html').default,
      },
      {
        name: 'app/views/admin/enterprise/enterpriseTab.html',
        contents: require('raw-loader!../views/admin/enterprise/enterpriseTab.html').default,
      },
      {
        name: 'app/views/admin/communications/communicationsTab.html',
        contents: require('raw-loader!../views/admin/communications/communicationsTab.html').default,
      },
      {
        name: 'app/views/admin/communications/editEmail.html',
        contents: require('raw-loader!../views/admin/communications/editEmail.html').default,
      },
      {
        name: 'app/views/admin/communications/editNotification.html',
        contents: require('raw-loader!../views/admin/communications/editNotification.html').default,
      },
      {
        name: 'app/views/admin/communications/editTextMessage.html',
        contents: require('raw-loader!../views/admin/communications/editTextMessage.html').default,
      },
      {
        name: 'app/views/admin/communications/previewEmail.html',
        contents: require('raw-loader!../views/admin/communications/previewEmail.html').default,
      },
      {
        name: 'app/views/admin/communications/previewNotification.html',
        contents: require('raw-loader!../views/admin/communications/previewNotification.html').default,
      },
      {
        name: 'app/views/admin/communications/previewTextMessage.html',
        contents: require('raw-loader!../views/admin/communications/previewTextMessage.html').default,
      },
      {
        name: 'app/views/admin/escalations/escalationModal.html',
        contents: require('raw-loader!../views/admin/escalations/escalationModal.html').default,
      },
      {
        name: 'app/views/admin/escalations/escalationsTab.html',
        contents: require('raw-loader!../views/admin/escalations/escalationsTab.html').default,
      },
      { name: 'app/views/admin/index.html', contents: require('raw-loader!../views/admin/index.html').default },
      {
        name: 'app/views/admin/jobs/jobModal.html',
        contents: require('raw-loader!../views/admin/jobs/jobModal.html').default,
      },
      {
        name: 'app/views/admin/jobs/jobsTab.html',
        contents: require('raw-loader!../views/admin/jobs/jobsTab.html').default,
      },
      {
        name: 'app/views/admin/meetingPlace/locationModal.html',
        contents: require('raw-loader!../views/admin/meetingPlace/locationModal.html').default,
      },
      {
        name: 'app/views/admin/meetingPlace/meetingPlaceModal.html',
        contents: require('raw-loader!../views/admin/meetingPlace/meetingPlaceModal.html').default,
      },
      {
        name: 'app/views/admin/meetingPlace/meetingPlaceTab.html',
        contents: require('raw-loader!../views/admin/meetingPlace/meetingPlaceTab.html').default,
      },
      {
        name: 'app/views/admin/pools/poolModal.html',
        contents: require('raw-loader!../views/admin/pools/poolModal.html').default,
      },
      {
        name: 'app/views/admin/pools/poolTab.html',
        contents: require('raw-loader!../views/admin/pools/poolTab.html').default,
      },
      {
        name: 'app/views/admin/price/priceModal.html',
        contents: require('raw-loader!../views/admin/price/priceModal.html').default,
      },
      {
        name: 'app/views/admin/price/priceTab.html',
        contents: require('raw-loader!../views/admin/price/priceTab.html').default,
      },
      {
        name: 'app/views/admin/provider/providerRegistrationModal.html',
        contents: require('raw-loader!../views/admin/provider/providerRegistrationModal.html').default,
      },
      {
        name: 'app/views/admin/provider/providerTab.html',
        contents: require('raw-loader!../views/admin/provider/providerTab.html').default,
      },
      {
        name: 'app/views/admin/requester/requesterRegistrationModal.html',
        contents: require('raw-loader!../views/admin/requester/requesterRegistrationModal.html').default,
      },
      {
        name: 'app/views/admin/requester/requesterTab.html',
        contents: require('raw-loader!../views/admin/requester/requesterTab.html').default,
      },
      {
        name: 'app/views/admin/role/roleModal.html',
        contents: require('raw-loader!../views/admin/role/roleModal.html').default,
      },
      {
        name: 'app/views/admin/role/roleTab.html',
        contents: require('raw-loader!../views/admin/role/roleTab.html').default,
      },
      {
        name: 'app/views/admin/user/invitationsModal.html',
        contents: require('raw-loader!../views/admin/user/invitationsModal.html').default,
      },
      {
        name: 'app/views/admin/user/inviteUserModal.html',
        contents: require('raw-loader!../views/admin/user/inviteUserModal.html').default,
      },
      {
        name: 'app/views/admin/user/userModal.html',
        contents: require('raw-loader!../views/admin/user/userModal.html').default,
      },
      {
        name: 'app/views/admin/user/userTab.html',
        contents: require('raw-loader!../views/admin/user/userTab.html').default,
      },
      {
        name: 'app/views/admin/saveJobLocationModal.html',
        contents: require('raw-loader!../views/admin/saveJobLocationModal.html').default,
      },
      {
        name: 'app/views/adminUsers/index.html',
        contents: require('raw-loader!../views/adminUsers/index.html').default,
      },
      { name: 'app/views/analytics/index.html', contents: require('raw-loader!../views/analytics/index.html').default },
      { name: 'app/views/footer/footer.html', contents: require('raw-loader!../views/footer/footer.html').default },
      { name: 'app/views/header/header.html', contents: require('raw-loader!../views/header/header.html').default },
      { name: 'app/views/job/birdsEye.html', contents: require('raw-loader!../views/job/birdsEye.html').default },
      {
        name: 'app/views/job/imageViewerModal.html',
        contents: require('raw-loader!../views/job/imageViewerModal.html').default,
      },
      { name: 'app/views/job/index.html', contents: require('raw-loader!../views/job/index.html').default },
      {
        name: 'app/views/job/jobReceiptModal.html',
        contents: require('raw-loader!../views/job/jobReceiptModal.html').default,
      },
      { name: 'app/views/job/jobRoot.html', contents: require('raw-loader!../views/job/jobRoot.html').default },
      { name: 'app/views/job/jobsTab.html', contents: require('raw-loader!../views/job/jobsTab.html').default },
      {
        name: 'app/views/job/massCancelModal.html',
        contents: require('raw-loader!../views/job/massCancelModal.html').default,
      },
      {
        name: 'app/views/job/providerModal.html',
        contents: require('raw-loader!../views/job/providerModal.html').default,
      },
      { name: 'app/views/login/index.html', contents: require('raw-loader!../views/login/index.html').default },
      {
        name: 'app/views/login/loginHelpModal.html',
        contents: require('raw-loader!../views/login/loginHelpModal.html').default,
      },
      {
        name: 'app/views/login/setupModal.html',
        contents: require('raw-loader!../views/login/setupModal.html').default,
      },
      {
        name: 'app/views/reporting/analytics/index.html',
        contents: require('raw-loader!../views/reporting/analytics/index.html').default,
      },
      {
        name: 'app/views/reporting/dashboard/index.html',
        contents: require('raw-loader!../views/reporting/dashboard/index.html').default,
      },
      { name: 'app/views/reporting/index.html', contents: require('raw-loader!../views/reporting/index.html').default },
      {
        name: 'app/views/reporting/mapViews/mapDetailViewModal.html',
        contents: require('raw-loader!../views/reporting/mapViews/mapDetailViewModal.html').default,
      },
      {
        name: 'app/views/reporting/mapViews/mapViews.html',
        contents: require('raw-loader!../views/reporting/mapViews/mapViews.html').default,
      },
      {
        name: 'app/views/reporting/timeReports/repeatJobOrderModal.html',
        contents: require('raw-loader!../views/reporting/timeReports/repeatJobOrderModal.html').default,
      },
      {
        name: 'app/views/reporting/timeReports/repeatDataShow.html',
        contents: require('raw-loader!../views/reporting/timeReports/repeatDataShow.html').default,
      },
      {
        name: 'app/views/reporting/timeReports/shiftDetails.html',
        contents: require('raw-loader!../views/reporting/timeReports/shiftDetails.html').default,
      },
      {
        name: 'app/views/reporting/timeReports/termsAndConditionsModal.html',
        contents: require('raw-loader!../views/reporting/timeReports/termsAndConditionsModal.html').default,
      },
      {
        name: 'app/views/reporting/timeReports/timeReports.html',
        contents: require('raw-loader!../views/reporting/timeReports/timeReports.html').default,
      },
      {
        name: 'app/views/reporting/timeReports/timeReportsChangeMobileModal.html',
        contents: require('raw-loader!../views/reporting/timeReports/timeReportsChangeMobileModal.html').default,
      },
      {
        name: 'app/views/reporting/timeReports/timeReportsMobile.html',
        contents: require('raw-loader!../views/reporting/timeReports/timeReportsMobile.html').default,
      },
      {
        name: 'app/views/reporting/verify/changeVerifyModal.html',
        contents: require('raw-loader!../views/reporting/verify/changeVerifyModal.html').default,
      },
      {
        name: 'app/views/reporting/verify/changeVerifyTab.html',
        contents: require('raw-loader!../views/reporting/verify/changeVerifyTab.html').default,
      },
      { name: 'app/views/roles/index.html', contents: require('raw-loader!../views/roles/index.html').default },
      {
        name: 'app/views/schedule/actionsModal.html',
        contents: require('raw-loader!../views/schedule/actionsModal.html').default,
      },
      {
        name: 'app/views/schedule/changeJobOrderModal.html',
        contents: require('raw-loader!../views/schedule/changeJobOrderModal.html').default,
      },
      {
        name: 'app/views/schedule/editJobOrderModal.html',
        contents: require('raw-loader!../views/schedule/editJobOrderModal.html').default,
      },
      { name: 'app/views/schedule/index.html', contents: require('raw-loader!../views/schedule/index.html').default },
      {
        name: 'app/views/schedule/pickListModal.html',
        contents: require('raw-loader!../views/schedule/pickListModal.html').default,
      },
      {
        name: 'app/views/schedule/scheduleJobModal.html',
        contents: require('raw-loader!../views/schedule/scheduleJobModal.html').default,
      },
      {
        name: 'app/views/schedule/transferSplitModal.html',
        contents: require('raw-loader!../views/schedule/transferSplitModal.html').default,
      },
      {
        name: 'app/views/session/changeAssignmentModal.html',
        contents: require('raw-loader!../views/session/changeAssignmentModal.html').default,
      },
      { name: 'app/views/session/index.html', contents: require('raw-loader!../views/session/index.html').default },
      {
        name: 'app/views/session/interestActionModal.html',
        contents: require('raw-loader!../views/session/interestActionModal.html').default,
      },
      {
        name: 'app/views/session/pickList.html',
        contents: require('raw-loader!../views/session/pickList.html').default,
      },
      {
        name: 'app/views/session/requestSessionModal.html',
        contents: require('raw-loader!../views/session/requestSessionModal.html').default,
      },
      {
        name: 'app/views/session/resendJobOrderModal.html',
        contents: require('raw-loader!../views/session/resendJobOrderModal.html').default,
      },
      {
        name: 'app/views/session/sessionNotifications.html',
        contents: require('raw-loader!../views/session/sessionNotifications.html').default,
      },
      {
        name: 'app/views/session/sessionQueue.html',
        contents: require('raw-loader!../views/session/sessionQueue.html').default,
      },
      {
        name: 'app/views/session/showInterestedModal.html',
        contents: require('raw-loader!../views/session/showInterestedModal.html').default,
      },
      {
        name: 'app/views/session/trackSessionModal.html',
        contents: require('raw-loader!../views/session/trackSessionModal.html').default,
      },
      {
        name: 'app/views/session/updateRequestAttribsModal.html',
        contents: require('raw-loader!../views/session/updateRequestAttribsModal.html').default,
      },
      { name: 'app/views/support/index.html', contents: require('raw-loader!../views/support/index.html').default },
      {
        name: 'app/views/templates/api_logs_filter.html',
        contents: require('raw-loader!../views/templates/api_logs_filter.html').default,
      },
      {
        name: 'app/views/templates/enterprise_filter.html',
        contents: require('raw-loader!../views/templates/enterprise_filter.html').default,
      },
      {
        name: 'app/views/templates/apply_indicator.html',
        contents: require('raw-loader!../views/templates/apply_indicator.html').default,
      },
      {
        name: 'app/views/templates/avatar_loggedin_status_legend.html',
        contents: require('raw-loader!../views/templates/avatar_loggedin_status_legend.html').default,
      },
      {
        name: 'app/views/templates/user_popover.html',
        contents: require('raw-loader!../views/templates/user_popover.html').default,
      },
      {
        name: 'app/views/templates/break_popup.html',
        contents: require('raw-loader!../views/templates/break_popup.html').default,
      },
      {
        name: 'app/views/templates/break_penalty.html',
        contents: require('raw-loader!../views/templates/break_penalty.html').default,
      },
      {
        name: 'app/views/templates/job_filter.html',
        contents: require('raw-loader!../views/templates/job_filter.html').default,
      },
      {
        name: 'app/views/templates/calendar.html',
        contents: require('raw-loader!../views/templates/calendar.html').default,
      },
      {
        name: 'app/views/templates/calendar_filter.html',
        contents: require('raw-loader!../views/templates/calendar_filter.html').default,
      },
      {
        name: 'app/views/templates/calendar_legend.html',
        contents: require('raw-loader!../views/templates/calendar_legend.html').default,
      },
      {
        name: 'app/views/templates/calendar_provider_schedule_actions.html',
        contents: require('raw-loader!../views/templates/calendar_provider_schedule_actions.html').default,
      },
      {
        name: 'app/views/templates/calendar_schedule_actions.html',
        contents: require('raw-loader!../views/templates/calendar_schedule_actions.html').default,
      },
      {
        name: 'app/views/templates/customer_review.html',
        contents: require('raw-loader!../views/templates/customer_review.html').default,
      },
      {
        name: 'app/views/templates/dynamic_attrib_fields.html',
        contents: require('raw-loader!../views/templates/dynamic_attrib_fields.html').default,
      },
      {
        name: 'app/views/templates/dynamic_child_attrib_fields.html',
        contents: require('raw-loader!../views/templates/dynamic_child_attrib_fields.html').default,
      },
      {
        name: 'app/views/templates/form_label.html',
        contents: require('raw-loader!../views/templates/form_label.html').default,
      },
      {
        name: 'app/views/templates/job_indicator.html',
        contents: require('raw-loader!../views/templates/job_indicator.html').default,
      },
      {
        name: 'app/views/templates/job_order_popover.html',
        contents: require('raw-loader!../views/templates/job_order_popover.html').default,
      },
      {
        name: 'app/views/templates/job_view_header.html',
        contents: require('raw-loader!../views/templates/job_view_header.html').default,
      },
      {
        name: 'app/views/templates/notificationWidget.html',
        contents: require('raw-loader!../views/templates/notificationWidget.html').default,
      },
      {
        name: 'app/views/templates/page_header.html',
        contents: require('raw-loader!../views/templates/page_header.html').default,
      },
      {
        name: 'app/views/templates/paging.html',
        contents: require('raw-loader!../views/templates/paging.html').default,
      },
      {
        name: 'app/views/templates/pick_worker.html',
        contents: require('raw-loader!../views/templates/pick_worker.html').default,
      },
      {
        name: 'app/views/templates/pleaseReviewWidget.html',
        contents: require('raw-loader!../views/templates/pleaseReviewWidget.html').default,
      },
      {
        name: 'app/views/templates/profile_picture.html',
        contents: require('raw-loader!../views/templates/profile_picture.html').default,
      },
      {
        name: 'app/views/templates/provider_stats.html',
        contents: require('raw-loader!../views/templates/provider_stats.html').default,
      },
      {
        name: 'app/views/templates/remove_select_all.html',
        contents: require('raw-loader!../views/templates/remove_select_all.html').default,
      },
      {
        name: 'app/views/templates/schedule_job_form_fields.html',
        contents: require('raw-loader!../views/templates/schedule_job_form_fields.html').default,
      },
      {
        name: 'app/views/templates/schedule_popover.html',
        contents: require('raw-loader!../views/templates/schedule_popover.html').default,
      },
      {
        name: 'app/views/templates/service_request_fields.html',
        contents: require('raw-loader!../views/templates/service_request_fields.html').default,
      },
      {
        name: 'app/views/templates/simple_pie_chart.html',
        contents: require('raw-loader!../views/templates/simple_pie_chart.html').default,
      },
      {
        name: 'app/views/templates/switch_select.html',
        contents: require('raw-loader!../views/templates/switch_select.html').default,
      },
      {
        name: 'app/views/templates/timezone_display.html',
        contents: require('raw-loader!../views/templates/timezone_display.html').default,
      },
      {
        name: 'app/views/templates/toggle_button.html',
        contents: require('raw-loader!../views/templates/toggle_button.html').default,
      },
      {
        name: 'app/views/templates/time_picker_popup_simple.html',
        contents: require('raw-loader!../views/templates/time_picker_popup_simple.html').default,
      },
      {
        name: 'app/views/templates/time_picker_popup_with_arrows.html',
        contents: require('raw-loader!../views/templates/time_picker_popup_with_arrows.html').default,
      },
      {
        name: 'app/views/templates/verifier_info.html',
        contents: require('raw-loader!../views/templates/verifier_info.html').default,
      },
      {
        name: 'app/views/templates/worker_filter.html',
        contents: require('raw-loader!../views/templates/worker_filter.html').default,
      },
      { name: 'app/views/workers/index.html', contents: require('raw-loader!../views/workers/index.html').default },
      {
        name: 'app/views/templates/content_editable.html',
        contents: require('raw-loader!../views/templates/content_editable.html').default,
      },
      { name: 'app/views/login/loggedOut.html', contents: require('raw-loader!../views/login/loggedOut.html').default },
      {
        name: 'app/views/templates/job_filter.html',
        contents: require('raw-loader!../views/templates/job_filter.html').default,
      },
      {
        name: 'app/views/templates/supervisor_signoff.html',
        contents: require('raw-loader!../views/templates/supervisor_signoff.html').default,
      },
      {
        name: 'app/views/templates/job_filter.html',
        contents: require('raw-loader!../views/templates/job_filter.html').default,
      },
      {
        name: 'app/views/admin/user/bulkLoadedModal.html',
        contents: require('raw-loader!../views/admin/user/bulkLoadedModal.html').default,
      },
      {
        name: 'app/views/admin/user/bulkInviteWarning.html',
        contents: require('raw-loader!../views/admin/user/bulkInviteWarning.html').default,
      },
    ];
    paths.forEach((item) => $templateCache.put(item.name, item.contents));
  },
]);
