import module from './module';
import * as countries from '../countries.json';

const GOOGLE_API_TOKEN = 'AIzaSyDbt6EpeUuk37vM2FzxpPUjeKRbBL8NUuE';

export default module.service('googleLocationService', [
  '$http',
  '$q',
  function ($http, $q) {
    return {
      geocodeAddress: function (address) {
        const deferred = $q.defer();
        $http({
          url: `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${GOOGLE_API_TOKEN}`,
          method: 'GET',
          skipHeaders: true,
        }).then((res) => {
          const data = res.data;
          if (data.status === 'OK' && data.results.length) {
            const result = data.results[0];
            if (result.geometry) {
              const geo = result.geometry;
              if (geo.location) {
                deferred.resolve(geo.location);
              }
            }
          }
          deferred.resolve(null);
        });
        return deferred.promise;
      },

      getTimezone: function (location, timestamp) {
        const deferred = $q.defer();
        if (location && timestamp && location.latitude && location.longitude) {
          $http({
            url: `https://maps.googleapis.com/maps/api/timezone/json?location=${location.latitude},${location.longitude}&timestamp=${timestamp}&key=${GOOGLE_API_TOKEN}`,
            method: 'GET',
            skipHeaders: true,
          }).then((res) => {
            const data = res.data;
            if (data.status === 'OK' && data.timeZoneId && data.timeZoneName) {
              deferred.resolve(data);
            }
            deferred.resolve(null);
          });
        } else {
          deferred.resolve(null);
        }
        return deferred.promise;
      },

      parseAddress: function (details, state_list) {
        const result = {};
        result.latitude = details.geometry.location.lat();
        result.longitude = details.geometry.location.lng();
        result.address_line_1 = details.name;

        const addressArray = details.address_components;

        const state = addressArray.filter(function (obj) {
          return obj.types[0] === 'administrative_area_level_1';
        });
        if (state && state.length) {
          const selectedState = state_list.filter(function (obj) {
            return obj.name === state[0].long_name;
          });
          if (selectedState && selectedState.length) {
            result.state = selectedState[0];
          }
        }
        const zip_code = addressArray.filter(function (obj) {
          return obj.types[0] === 'postal_code';
        });
        if (zip_code && zip_code.length) {
          result.zip_code = zip_code[0].short_name;
        }

        let city = addressArray.filter(function (obj) {
          return obj.types[0] === 'locality' || obj.types[0] === 'postal_town';
        });
        if (!city || !city.length) {
          city = addressArray.filter(function (obj) {
            return obj.types[0] === 'sublocality_level_1';
          });
        }
        if (city && city.length) {
          result.city = city[0].long_name;
        }

        const country = addressArray.filter(function (obj) {
          return obj.types[0] === 'country';
        });
        if (country && country.length) {
          result.country = country[0].long_name;
          const foundCountry = countries.default.find((x) => x['alpha-2'] === country[0].short_name);
          if (foundCountry) {
            result.countryCode = parseInt(foundCountry['country-code']);
          }
          result.isForeign = country[0].short_name !== 'US';
        }
        return result;
      },
    };
  },
]);
