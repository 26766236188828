import module from './module';
import moment from 'moment';
import angular from 'angular';

export default module.factory('Provider', [
  '$http',
  'BASE_URL',
  '$localStorage',
  'TimeFormatService',
  function ($http, BASE_URL, $localStorage, TimeFormatService) {
    function Provider(providerData, dataType) {
      if (providerData) {
        this.setData(providerData, dataType);
      }
    }
    // This is to assign attrib values to display_value for table profile info view display
    function setProfileAttribInfo(attrib) {
      switch (attrib.widget_type) {
        case 1: // standard text
          attrib.display_value = attrib.data || 'N/A';
          break;
        case 3: // single list
          if (attrib.choice_list) {
            var values = attrib.choice_list.filter(function (obj) {
              return obj.selected == true;
            });
          } else {
            var values = attrib.value_list.filter(function (obj) {
              return obj.selected == true;
            });
          }
          var displayValues = values.map(function (obj) {
            return obj.name;
          });
          attrib.display_value = displayValues.join(', ');
          break;
        case 4: // multi list
          var values = attrib.choice_list.filter(function (obj) {
            return obj.selected == true;
          });
          var displayValues = values.map(function (obj) {
            return obj.name;
          });
          attrib.display_value = displayValues.join(', ');
          break;
        case 5: // date (month, day, year)
          var formated_date = moment
            .utc(attrib.data, TimeFormatService.format('fullDateNoSeparator'))
            .local()
            .format('DD-MMM-YY');
          attrib.display_value = formated_date;
          break;
        case 6: // time (hour and minute of day)
          break;
        case 7: // date and time
          var formated_date_time = moment
            .utc(attrib.value_text, TimeFormatService.format('fullDateTimeNoSeparator'))
            .local()
            .format(TimeFormatService.format('dateTimeLong'));
          attrib.display_value = formated_date_time;
          break;
        case 9: // boolean flag
          attrib.display_value = attrib.data ? 'Yes' : 'No';
          break;
        case 10: // count (integer)
          attrib.display_value = attrib.data;
          break;
        case 11: // location
          var value = attrib.value_location.filter(function (obj) {
            return obj.selected == true;
          })[0];
          attrib.display_value = (value || {}).name;
          break;
        case 12: // meeting place
          var value = attrib.value_place.filter(function (obj) {
            return obj.selected == true;
          })[0];
          if (value && value.name) {
            attrib.display_value = value.name;
          } else {
            attrib.display_value = 'N/A';
          }
          break;
        case 13: // Standard Decimal (16 digits with 2 decimal places)
          attrib.display_value = attrib.value_decimal ? attrib.value_decimal : attrib.data ? attrib.data : 'N/A';
          break;
        case 15: // Dual Line selection
          // eslint-disable-next-line no-case-declarations
          const displayNames = attrib.choice_list?.filter(c => c.selected)?.map(s => s.name) ?? [];
          attrib.display_value = displayNames.join(', ');
          break;
        case 16: // Profile Picture Image
          break;
        case 18: // Count (Integer) with Plus (+) and Minus (-)
          attrib.display_value = attrib.value_count ? attrib.value_count + ' minutes' : null;
          break;
        case 19: // Count (Integer) for Rating
          attrib.display_value = attrib.value_count ? attrib.value_count + ' star rating' : 'N/A';
          break;
        case 20: // Count (Integer) for Length of Time (in minutes)
          attrib.display_value = attrib.value_count ? attrib.value_count + ' minutes' : 'N/A';
          break;
        case 21: // Phone Number
          attrib.display_value = attrib.data ? attrib.data : 'N/A';
          break;
        case 22: // Email Address
          break;
      } // end of switch
    }

    function setProfileAttribs(profile_list) {
      angular.forEach(profile_list, function (profileAttrib) {
        setProfileAttribInfo(profileAttrib);
      });
    }

    Provider.prototype = {
      setData: function (providerData, dataType) {
        angular.extend(this, providerData);
        const profileInfo = this.profile_info.profile_info || this.profile_info;

        profileInfo.sort(function (a, b) {
          return a.order - b.order;
        });

        setProfileAttribs(profileInfo);

        this.first_name = profileInfo.filter(function (obj) {
          return obj.name === 'first_name';
        })[0].data;
        this.last_name = profileInfo.filter(function (obj) {
          return obj.name === 'last_name';
        })[0].data;
        this.full_name = this.first_name + ' ' + this.last_name;
        this.profile_picture =
          profileInfo.filter(function (obj) {
            return obj.name === 'profile_picture';
          })[0] || providerData.profile_picture;

        if (typeof this.vetting_status === 'number') {
          this.vetting_status_code = this.vetting_status;
        } else {
          this.vetting_status = profileInfo.filter(function (obj) {
            return obj.name === 'provider_vetting_status';
          })[0];
          this.vetting_status_code =
            this.status ||
            (this.vetting_status && this.vetting_status.choice_list && this.vetting_status.choice_list[0].choice_id);
        }

        if (typeof this.login_status === 'number') {
          this.login_status_code = this.login_status;
        } else {
          this.login_status = profileInfo.filter(function (obj) {
            return obj.name === 'login_status';
          })[0];
          this.login_status_code =
            this.login_status && this.login_status.choice_list && this.login_status.choice_list[0].choice_id;
        }
      },
      displayAttribValue: function (attrib) {
        const attribObj = this.profile_info.filter(function (obj) {
          return obj.name.toLowerCase() === attrib.toLowerCase();
        })[0];
        if (attribObj && attribObj.display_value) {
          return attribObj.display_value;
        } else {
          return 'N/A';
        }
      },
    };

    return Provider;
  },
]);
